<template>
    <div class="project-bx h-100">
<!--        <div class="heart-pro">-->
<!--            <a href="#" :class="{'active': item.is_in_wishlist == '1'}"><i class="fa fa-heart-o"></i></a>-->
<!--        </div>                            -->

        <picture v-if="item.actual_unit_photo && item.actual_unit_photo.length > 0">
            <img :src="getImageUrl(item.actual_unit_photo[0].photo_name)" @error="handleImageError" alt="Property Image" />
        </picture>
        <picture v-else>
            <img :src="CONSTANT.DEFAULT_NO_IMAGE"  @error="handleImageError" alt="Property Image" />
        </picture>
        <div class="project-info">
            <div class="d-md-flex mb-2 align-items-center">
                <h3><a v-bind:href="'/property-detail/' +  item.id ">{{ item.property_name }}</a></h3>
                <div class="price-pro ms-md-auto">
                    <span><small>s$</small> {{item.asking_price_sale_rent}}</span>
                </div>
            </div>
            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ item.street }},{{ item.area_hdb_estate }},{{ item.district }}</p>

            <div class="d-flex flex-column">
                <div class="mt-auto">
                    <div class="pro-specify">
                        <span><i><img src="frontend/images/spec-ico1.svg"></i>{{item.no_of_rooms}}</span>
                        <span><i><img src="frontend/images/spec-ico2.svg"></i>{{ item.no_of_baths }}</span>
                        <span><i><img src="frontend/images/spec-ico3.svg"></i>{{item.no_car_park_lots}}</span>
                        <span><i><img src="frontend/images/spec-ico4.svg"></i>{{item.floor_area}} {{item.floor_area_unit}}</span>
                    </div>
                    <div class="pro-tag">
                        <a style="cursor:default">{{ item.property_type_details.parent_id_top_level_1_code }}</a>
                        <a style="cursor:default">{{ item.property_type_details.code }}</a>
                        <a style="cursor:default">{{ item.land_title_type }}</a>
                    </div>
                    <ul>
                        <li v-if="item.is_direct_owner">
                            <figure>
                                <img src="frontend/images/specify-ico1.png">
                            </figure>
                            <small>Direct<br>Owner</small>
                        </li>
                        <li  v-if="item.is_sing_pass || item.is_corp_pass ">
                            <figure>
                                <img src="frontend/images/specify-ico2.png">
                            </figure>
                            <small>SingPass<br>Verified </small>
                        </li>
                        <li v-if="item.is_document_approved">
                            <figure>
                                <img src="frontend/images/specify-ico3.png">
                            </figure>
                            <small>Documents<br>Verified</small>
                        </li>
                        <li v-if="item.is_FSM_linked">
                            <figure>
                                <img src="frontend/images/specify-ico4.png">
                            </figure>
                            <small>Fengshui<br>Map</small>
                        </li>
                        <li v-if="item.is_assigned_to_the_agent">
                            <figure>
                                <img src="frontend/images/specify-ico5.png">
                            </figure>
                            <small>Hybrid-Listing<br>Owners </small>
                        </li>
                        <li v-if="item.is_below_mean_value">
                            <figure>
                                <img src="frontend/images/Down-arrow.svg">
                            </figure>
                            <small>BMV</small>
                        </li>
                    </ul>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import { CONSTANT } from '@/constants/constants';

export default {
    name: 'PropertyBox',

    props: {
        item: {
            type: Object,
            Required: true
        },
    },

    data() {
        return {     
            CONSTANT   
        }
    },

    methods: {
        getImageUrl(photoName) {        
            return photoName ? `${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${photoName}` : CONSTANT.DEFAULT_IMAGE;
        },
        redirectToDetails() {
            window.location.href = 'https://pv-frontend.tess.gg/property-detail/28';        
        }
    }
}
</script>