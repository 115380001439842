<template>
  <section class="profile-se">
    <div class="container">
      <div class="top-tabs-design">
        <ul>
          <li><a href="javascript:;" class="active">My profile</a></li>
<!--          <li><a href="/leads-&-status-management">Leads & Status Management</a></li>-->
<!--          <li><a href="/account">Account</a></li>-->
<!--          <li><a href="/wishlist">Wishlist - Saved & Favorites</a></li>-->
<!--          <li><a href="/reports">Reports & Performance Scores</a></li>-->
<!--          <li><a href="/ai-creatives">AI Creatives</a></li>-->
        </ul>
      </div>
      <div class="row">
        <div class="col-md-9">
          <LoaderCust :isLoading="isLoading" />
          <form @submit.prevent="updateProfileBasic">
            <div class="profile-card-edit">
              <div class="comman-title">
                <h3>Profile Picture</h3>
              </div>
              <div class="profile-card-edit-box">
                <figure v-if="imageUrl">
                  <!-- <img :src="imageUrl" > -->
                  <img :src="computedImageSrc" @error="handleImageError" >
                </figure>

                <div class="profile-user-content w-100">
                  <div class="form-group" @input="handleInputChangeUsername"
                       v-if="((this.userProfile.is_corporate || this.userProfile.is_business) && this.userProfile.is_active )">
                    <label>User Name</label>
                    <input type="text" placeholder="Username" class="form-control" v-model="this.userProfile.user_name_url" >
                  </div>
                  <div class="d-flex">
                    <input type="file" ref="fileInput" @change="onFileChange" style="display: none;" />
                    <a href="javascript:;"  @click.prevent="triggerFileInput" class="comman-btn-2"><b>Upload Profile Picture</b></a>
                    <a href="javascript:;" @click.prevent="removeProfilePhoto"  class="comman-btn-4 ms-3"><b>Remove Picture</b></a>
                  </div>
						<!-- <div class="d-flex">{{referal_current_Url}}</div> -->
						<div class="d-flex">
							<div class="url-container">
								<small>Share link to post properties to owner or colleague</small>
									<div class="url-box">
										<div>{{ referal_current_Url }}</div>
										<button @click="copyToClipboard" type="button" class="btn btn-warning btn-sm">Copy URL</button>
									</div>
									<p v-if="copySuccess" class="copy-success">URL copied to clipboard!</p>
							</div>
						</div>

                </div>
              </div>
            </div>
            <div class="profile-card-edit">
              <div class="comman-title">
                <h3>Your Profile</h3>
              </div>
              <div class="row">
                <div class="form-group col-md-3">
                  <label>First Name </label>
                  <input type="text" placeholder="First Name" class="form-control" v-model="this.form.first_name" >
                </div>
                <div class="form-group col-md-3">
                  <label>Last Name</label>
                  <input type="text" placeholder="Last Name" class="form-control" v-model="this.form.last_name"  >
                </div>
                <div class="form-group col-md-3">
                  <label>Birth Date</label>
                  <input type="date" placeholder="Last Name" class="form-control" v-model="this.form.date_of_birth">
                </div>
                <div class="form-group col-md-3">
                  <label>Gender</label>
                  <select class="form-control" v-model="this.form.gender">
                    <option value="">Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="profile-card-edit">
              <div class="comman-title">
                <h3>Your Contact</h3>
              </div>
              <div class="row">
                <div class="form-group col-md-4">
                  <label>Phone Number</label>
                  <input type="text" placeholder="Phone Number" class="form-control"
                         @blur="validatePhone"
                         :class="{ 'is-invalid': verifyError }"
                         v-model="this.form.contact_phone">
                  <span v-if="phoneError" class="error">{{ phoneError }}</span>
                </div>
                <div class="form-group col-md-4">
                  <label>Verify Phone Number</label>
                  <input type="text" placeholder="Verify Phone Number"
                         class="form-control"
                         v-model="form.verify_phone"
                         @blur="validateVerifyPhone"
                         :class="{ 'is-invalid': verifyError }"
                  >
                  <span v-if="verifyError" class="error">{{ verifyError }}</span>
                </div>
                <div class="form-group col-md-4">
                  <label>Email</label>
                  <input type="email" placeholder="Email" class="form-control" v-model="this.form.contact_email">
                </div>
                <div class="form-group col-md-4">
                  <label>Facebook</label>
                  <input type="text" placeholder="Facebook" class="form-control" v-model="this.form.facebook_url">
                </div>
                <div class="form-group col-md-4">
                  <label>Linkedin</label>
                  <input type="text" placeholder="Linkedin" class="form-control" v-model="this.form.linkedin_url">
                </div>
                <div class="form-group col-md-4">
                  <label>Twitter</label>
                  <input type="text" placeholder="Twitter" class="form-control" v-model="this.form.twitter_url">
                </div>
                <div class="form-group col-md-4">
                  <label>Instagram</label>
                  <input type="text" placeholder="Instagram" class="form-control" v-model="this.form.instagram_url">
                </div>
                <div class="form-group col-md-4">
                  <label>Tiktok</label>
                  <input type="text" placeholder="Tiktok" class="form-control" v-model="this.form.tiktok_url">
                </div>
                <div class="form-group col-md-4">
                  <label>YouTube</label>
                  <input type="text" placeholder="YouTube" class="form-control" v-model="this.form.youtube_url">
                </div>
              </div>
            </div>
				<div class="profile-card-edit">
              <div class="comman-title">
                <h3>About You</h3>
              </div>
              <div class="form-group mb-0">
                <label>About You</label>
                <textarea class="form-control" placeholder="About You" v-model="this.form.about_you"></textarea>
              </div>
            </div>
            <div class="profile-card-edit d-flex">
              <button class="btn comman-btn-2" type="submit">Save</button>
              <button class="btn comman-btn-4 ms-2">Cancel</button>
            </div>
          </form>
          
          <form @submit.prevent="updateServicedData">
            <div class="profile-card-edit">
              <div class="comman-title">
                <h3>Agency/Company </h3>
              </div>
              <div class="row">
                <div class="form-group col-md-3">
                  <label>Agency Name</label>
                  <input type="text" placeholder="Agency Name" class="form-control" v-model="this.form.agency_name">
						<span  class="error" v-if="errors.agency_name">{{ errors.agency_name }}</span>
                </div>
                <div class="form-group col-md-3">
                  <label>Agency License</label>
                  <input type="text" placeholder="Agency License" class="form-control" v-model="this.form.agency_license">
                </div>
                <div class="form-group col-md-3">
                  <label>Agency Registration No</label>
                  <input type="text" placeholder="Agency Registration No" class="form-control" v-model="this.form.agency_registration_no">
                </div>
                <div class="form-group col-md-3">
                  <label>Position Held</label>
                  <input type="text" placeholder="Position Held" class="form-control" v-model="this.form.position_held">
						<span  class="error" v-if="errors.position_held">{{ errors.position_held }}</span>
                </div>
                <div class="form-group mb-0 col-md-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="show_as_agent" v-model="this.form.show_as_agent" >
                    <label class="form-check-label w-100" for="show_as_agent">
                      Estate Agent<a href="#"> Sign-Up / Upgrade</a>
                    </label>
                  </div>
                </div>
              </div>
            </div>          

          
            <div class="profile-card-edit">
              <div class="comman-title">
                <h3>Your Services</h3>
              </div>
              <div class="row">
                <div class="form-group col-md-4">
                  <label>My Services</label>
                  <!-- <select class="form-control" v-model="this.form.service_type_id">
                    <option value="1">My Services</option>
                  </select> -->
                  <select id="service_type_id" v-model="form.service_type_id" class="form-control" >
                    <!-- <option value="" disabled selected>Please Facilities</option> -->
                    <option value="0">Select Service</option>
                    <option v-for="option in service_typeList" :key="option.value" :value="option.value">
                      {{ option.label }}
                    </option>
                  </select>
						<span  class="error" v-if="errors.service_type_id">{{ errors.service_type_id }}</span>
                </div>
                <div class="form-group col-md-4">
                  <label>Company Name</label>
                  <input type="text" placeholder="Company Name" class="form-control" v-model="this.form.company_name">
                </div>

                <div class="form-group col-md-4">
                  <label>Working Hours</label>
                  <input type="text" placeholder="Working Hours" class="form-control" v-model="this.form.working_hours">
                </div>
              </div>
              <div class="row">
                <div class="form-group">
                  <label>Availability Days</label>
                  <input type="text" placeholder="Availability Days" class="form-control"  v-model="this.form.availability_days">
                  <div class="form-group">
                    <div class="form-check form-check-inline">
                      <label class="form-check-label w-100" for="is_available_mon">Monday</label>
                      <input class="form-check-input" type="checkbox" v-model="form.is_available_mon" id="is_available_mon">
                    </div>
                    <div class="form-check form-check-inline">

                      <label class="form-check-label w-100" for="is_available_tue">Tuesday</label>
                      <input class="form-check-input" type="checkbox" v-model="form.is_available_tue" id="is_available_tue">
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label w-100" for="is_available_wed">Wednesday</label>
                      <input class="form-check-input" type="checkbox" v-model="form.is_available_wed" id="is_available_wed">
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label w-100" for="is_available_thu">Thursday</label>
                      <input class="form-check-input" type="checkbox" v-model="form.is_available_thu" id="is_available_thu">
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label w-100" for="is_available_fri">Friday</label>
                      <input class="form-check-input" type="checkbox" v-model="form.is_available_fri" id="is_available_fri">
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label w-100" for="is_available_sat">Saturday</label>
                      <input class="form-check-input" type="checkbox" v-model="form.is_available_sat" id="is_available_sat">
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label w-100" for="is_available_sun">Sunday</label>
                      <input class="form-check-input" type="checkbox" v-model="form.is_available_sun" id="is_available_sun">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="profile-card-edit">
              <div class="comman-title">
                <h3>Your Experience</h3>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label>Service</label>
                  <select id="personal_service_type_id" v-model="form.personal_service_type_id" class="form-control">
                    <option value="0">Select Service</option>
                    <option v-for="option in service_typeList" :key="option.value" :value="option.value">
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label>Working Since</label>
                  <!-- <input type="date" placeholder="Working Since" class="form-control" v-model="this.form.personal_working_since"> -->
                  <select v-model="this.form.personal_working_since" id="year-select" class="form-control">
                    <option value="">Working Since</option>
                    <option value="0">Select Working Since</option>
                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                  </select>
                </div>
              </div>
            </div>
            
            <!-- <button class="btn comman-btn-2 ms-3">Save</button>	 -->
            <div class="profile-card-edit d-flex">
              <button class="btn comman-btn-2" type="submit">Save</button>
              <button class="btn comman-btn-4 ms-2">Cancel</button>
            </div>
          </form>
        </div>
        <div class="col-md-3">
          <div class="profile-package-card">
            <h3  v-if="this.package_details">Package</h3>
            <h4  v-if="this.package_details">{{this.package_details.title}}</h4>
            <p  v-if="this.package_details">Valid Until {{this.package_details.paf_todate}}</p>
            <a href="/packages" class="comman-btn-2"><img src="frontend/images/Choose-plan.svg" alt=""> Choose plan</a>
            <br>
            <h4>Credits</h4>
            <p><b>{{ userProfile.available_credit_points ?? 'N/A' }} Credits</b></p>
            <router-link class="comman-btn-4" :to="{ name: 'TopUp' }"><img src="frontend/images/Choose-plan.svg" alt=""> Top-Up</router-link>
          </div>
          <div class="profile-package-card">
            <h4 class="mb-0">Availability</h4>
            <p v-if="profilServicesData"> {{ profilServicesData.availability_days }} {{profilServicesData.working_hours}}</p>
            <h4 class="mb-0">Reach Me</h4>
            <p class="mb-0">{{this.userProfile.email}}</p>
            <p>{{this.userProfile.contact_phone}}</p>
            <h4 >My Socials</h4>
            <div class="social">
              <a :href="this.userProfile.facebook_url" v-if="this.userProfile.facebook_url" target="_blank">
                <img src="frontend/images/fb-ico.svg">
              </a>
              <a :href="this.userProfile.linkedin_url" v-if="this.userProfile.linkedin_url" target="_blank">
                <img src="frontend/images/linkedin-ico.svg">
              </a>
              
              <a :href="this.userProfile.twitter_url" v-if="this.userProfile.twitter_url" target="_blank">
                <img src="frontend/images/tweet-ico.svg">
              </a>
              
              <a :href="this.userProfile.instagram_url" v-if="this.userProfile.instagram_url" target="_blank">
                <img src="frontend/images/icons8-instagram-36.svg">
              </a>
              
              <a :href="this.userProfile.tiktok_url" v-if="this.userProfile.tiktok_url" target="_blank">
                <img src="frontend/images/tiktok-icon.svg">
              </a>
              
              <a :href="this.userProfile.youtube_url" v-if="this.userProfile.youtube_url" target="_blank">
                <img src="frontend/images/youtube-ico.svg">
              </a>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LoaderCust from '@/components/LoaderCust.vue';
import { CONSTANT } from '@/constants/constants';
import { mapState } from 'vuex';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
export default {
  components: {
        LoaderCust,
    },
  data() {
    return {
		referal_current_Url :'',
		copySuccess: false,
      isLoading: false,  
      //userProfile: null, // Initial state
      selectedYear: new Date().getFullYear(), // Default to current year
      years: [],
      service_typeList: [],
      package_details: [],
      myprofileData: [],
      profilServicesData: [],
      selectedFile: null,
      imageUrl: 'frontend/images/profile.svg',
      form: {
        first_name:'',
        last_name:'',
        gender:'',
        date_of_birth:'',
        contact_phone:'',
        contact_email:'',
        facebook_url:'',
        linkedin_url:'',
        twitter_url:'',
        instagram_url:'',
        tiktok_url:'',
        youtube_url:'',
        about_you:'',

        agency_name:'',
        agency_license:'',
        agency_registration_no:'',
        position_held:'',
        personal_service_type_id: 0,
        personal_working_since:'',
        service_type_id: 0,
        show_as_agent: 0,
        is_available_mon:0,
        is_available_tue:0,
        is_available_wed:0,
        is_available_thu:0,
        is_available_fri:0,
        is_available_sat:0,
        is_available_sun:0,

      },
      errors: {},
      phoneError: '',
      verifyError: ''
    };
  },
  mounted() {
    this.referal_current_Url = `${window.location.protocol}//${window.location.host}/`; // Get base URL
  },
  computed: {
    ...mapState(['isAuthenticated', 'user','userProfile']),
    computedImageSrc() {
      return this.userProfile.profile_photo_name ? `${CONSTANT.IMAGE_URL}/imagesProfile/${this.userProfile.profile_photo_name}` : CONSTANT.DEFAULT_IMAGE;
    }
  },
  async created() {
    this.fetchOptions();
    this.generateYears();
  },
  methods: {
	copyToClipboard() {
      navigator.clipboard.writeText(this.referal_current_Url)
        .then(() => {
          this.copySuccess = true;
          setTimeout(() => {
            this.copySuccess = false; // Reset success message after 2 seconds
          }, 2000);
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
    },
    validateForm() {
			this.errors = {};
        if (!this.form.agency_name ) {
            this.errors.agency_name = 'Agency Name is required.';
        }
        if (!this.form.position_held ) {
            this.errors.position_held = 'Position Held is required.';
        }
        if (!this.form.service_type_id ) {
            this.errors.service_type_id = 'My Services is required.';
        }
      //   if (!this.form.availability_days ) {
      //       this.errors.availability_days = 'Availability Days is required.';
      //   }
                 
        return Object.keys(this.errors).length === 0;
    },     
    async onFileChange(event) {
      // console.log(this.user.id);
      this.selectedFile = event.target.files[0];
      console.log("File selected:", this.selectedFile);
      if (this.selectedFile) {
        // Generate a preview URL
        this.imageUrl = URL.createObjectURL(this.selectedFile);
        this.computedImageSrc = URL.createObjectURL(this.selectedFile);

        const token = localStorage.getItem('userToken');
        // Create a FormData object
        const formData = new FormData();
        formData.append('profile_photo_name', this.selectedFile);
        formData.append('dataJson', JSON.stringify({
          user_id: this.user.id,
          api_token: token,
        }));
        try {
          const response = await this.$axios.post('api/update-profile-photo', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          toastr.success(response.data.message, 'Success', { timeOut: 3000 });


          const response_profile = await this.$axios.post('/api/profile', {
            api_token: token,
            user_id: this.user.id,
          });
          // this.form.verify_phone = response_profile.data.output.contact_phone
          console.log('API Response:', response_profile.data); // Check API response
          await this.$store.dispatch('profileUpdate', response_profile.data.output);

          //await this.$store.dispatch('profileUpdate', response.data);
        } catch (error) {
          if (error.response && error.response.data) {
            this.errors = this.extractErrors(error.response.data.errors || {});
          } else {
            console.error('An unexpected error occurred:', error);
          }
        } finally {
          this.isLoading = false;
        }
      }
    },
    async uploadImage_000() {
      if (!this.selectedFile) {
        alert("No file selected.");
        return;
      }
      const token = localStorage.getItem('userToken');
      // Create a FormData object
      const formData = new FormData();
      formData.append('profile_photo_name', this.selectedFile);
      formData.append('dataJson', JSON.stringify({
        user_id: this.user.id,
        api_token: token,
      }));
      try {
        const response = await this.$axios.post('api/update-profile-photo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toastr.success(response.data.message, 'Success', { timeOut: 3000 });
        await this.$store.dispatch('profileUpdate', response.data);
      } catch (error) {
        if (error.response && error.response.data) {
          this.errors = this.extractErrors(error.response.data.errors || {});
        } else {
          console.error('An unexpected error occurred:', error);
        }
      } finally {
        this.isLoading = false;
      }
    },
    triggerFileInput() {
      // Trigger the file input click event
      this.$refs.fileInput.click();
    },
    async removeProfilePhoto() {

      const token = localStorage.getItem('userToken');
      const response_remove_photo = await this.$axios.post('/api/remove-profile-photo', {
        user_id: this.user.id,
        api_token: token
      });
      console.log('API Remove photo Response:', response_remove_photo.data);
      //await this.$store.dispatch('profileUpdate', response_remove_photo.data);
    },
    validatePhone() {
      const phonePattern = /^[0-9]{9,15}$/; // Adjust pattern as needed
      this.phoneError = phonePattern.test(this.form.contact_phone)
        ? ''
        : 'Invalid phone number. Must be between 9 to 15 digits including country code.';
    },
    validateVerifyPhone() {
      if (this.form.verify_phone !== this.form.contact_phone) {
        this.verifyError = 'Phone numbers do not match.';
      } else {
        this.verifyError = '';
      }
    },
    async updateProfileBasic() {
      this.validatePhone();
      this.validateVerifyPhone();

      if (this.phoneError || this.verifyError) {
        toastr.error(`Verify Phone Number not match!`, 'error', { timeOut: 3000 });
      }else{

        if (this.isLoading) return;
        this.isLoading = true;
        const token = localStorage.getItem('userToken');
        try {
          const response = await this.$axios.post('api/update-basic-profile', {
            user_id:this.user.id,
            api_token:token,
            first_name: this.form.first_name,
            last_name: this.form.last_name,
            gender: this.form.gender,
            date_of_birth: this.form.date_of_birth,
            contact_phone: this.form.contact_phone,
            contact_email: this.form.contact_email,
            facebook_url: this.form.facebook_url,
            linkedin_url: this.form.linkedin_url,
            twitter_url: this.form.twitter_url,
            instagram_url: this.form.instagram_url,
            tiktok_url: this.form.tiktok_url,
            youtube_url: this.form.youtube_url,
            about_you: this.form.about_you,

          });
          //this.updateServicedData();
          toastr.success(response.data.message, 'Success', { timeOut: 3000 });

        } catch (error) {

          if (error.response && error.response.data) {
            this.errors = this.extractErrors(error.response.data.errors || {});
          } else {
            console.error('An unexpected error occurred:', error);
          }
        } finally {
          this.isLoading = false;
        }
      }
    },
    async updateServicedData() {
      
      if (this.validateForm()) {
          this.isLoading = true;
          var show_as_agent = this.form.show_as_agent == true ? 1 : 0
          const token = localStorage.getItem('userToken');
          try {
            const response = await this.$axios.post('api/add-update-user-services', {
              user_id:this.user.id,
              api_token:token,
              user_service_detail_id:0,
              agency_name:this.form.agency_name ? this.form.agency_name : '',
              agency_license:this.form.agency_license ? this.form.agency_license : '',
              agency_registration_no:this.form.agency_registration_no ? this.form.agency_registration_no : '',
              position_held:this.form.position_held ? this.form.position_held : '',
              service_type_id:this.form.service_type_id ? this.form.service_type_id : '',
              company_name:this.form.company_name ? this.form.company_name : '',
              availability_days:this.form.availability_days ? this.form.availability_days : 0,
              is_available_mon:this.form.is_available_mon,
              is_available_tue:this.form.is_available_tue,
              is_available_wed:this.form.is_available_wed,
              is_available_thu:this.form.is_available_thu,
              is_available_fri:this.form.is_available_fri,
              is_available_sat:this.form.is_available_sat,
              is_available_sun:this.form.is_available_sun,
              working_hours:this.form.working_hours ? this.form.working_hours :0,
              show_as_agent:show_as_agent,
              personal_service_type_id:this.form.personal_service_type_id,
              personal_working_since:this.form.personal_working_since ? this.form.personal_working_since : 0,

            });

				this.profilServicesData = response.data.output;
            toastr.success(response.data.message, 'Success', { timeOut: 3000 });

          } catch (error) {

            if (error.response && error.response.data) {
              this.errors = this.extractErrors(error.response.data.errors || {});
              toastr.error('Something went wrong', 'error', { timeOut: 3000 });
            } else {
              // console.error('An unexpected error occurred:', error);
              toastr.error('Something went wrong', 'error', { timeOut: 3000 });
            }
          } finally {
            this.isLoading = false;
          }
      }
    },
    async handleInputChangeUsername() {

      try {
        const token = localStorage.getItem('userToken');
        await this.$axios.post('api/update-user-name-url', {
          user_id:this.user.id,
          api_token:token,
          user_name_url: this.userProfile.user_name_url
        });

      } catch (error) {

        if (error.response && error.response.data) {
          this.errors = this.extractErrors(error.response.data.errors || {});
        } else {
          console.error('An unexpected error occurred:', error);
        }
      } finally {
        this.isLoading = false;
      }
    },
    extractErrors(errors) {
      // Extract the first error message from each field's errors array
      const formattedErrors = {};
      for (const [field, messages] of Object.entries(errors)) {
        if (Array.isArray(messages) && messages.length > 0) {
          formattedErrors[field] = messages[0]; // Get the first error message
        }
      }
      return formattedErrors;
    },
    generateYears() {
      const currentYear = new Date().getFullYear();
      for (let year = 1970; year <= currentYear; year++) {
        this.years.push(year);
      }
    },
    async fetchOptions() {

      const token = localStorage.getItem('userToken');
      // Get Features list
      try {
        const response = await this.$axios.post('api/services-type-list',
          {
            user_id : this.user.id,
            api_token : token
          });
        this.service_typeList = response.data.output.map(item => ({
          value: item.id,
          label: item.name
        }));
      } catch (error) {
        console.error('Error fetching options:', error);
      }
      // Get Srviced data
      // try {
      //   const response = await this.$axios.post('api/my-profile-detail',
      //     {
      //       user_id : this.user.id,
      //       api_token : token
      //     });
      //   this.myprofileData = response.data.output
      //   this.profilServicesData = this.myprofileData.service_details
      // } catch (error) {
      //   console.error('Error fetching options:', error);
      // }
      // If Profile data
      if (this.isAuthenticated) {
        try {
          const response = await this.$axios.post('/api/my-profile-detail', {
            api_token: token,
            user_id:this.user.id
          });

          var userData = response.data.output;
          await this.$store.dispatch('profileUpdate', response.data.output);
          var userServiceData = userData.service_details[0];
          this.package_details = userData.package_details[0];
          this.profilServicesData = userServiceData;
			this.referal_current_Url =  this.referal_current_Url+'widget-property-post/'+userData.id+'/'+userData.referral_user_type;
          // console.log('-----------------------')
          // console.log(userData);
          // console.log(userServiceData.length);
          // console.log('-----------------------')
          this.form.first_name =  userData.first_name,
            this.form.last_name =  userData.last_name,
            this.form.gender =  userData.gender,
            this.form.date_of_birth =  userData.date_of_birth,
            this.form.contact_email =  userData.contact_email,
            this.form.contact_phone =  userData.contact_phone,
            this.form.verify_phone =  userData.contact_phone,
            this.form.facebook_url =  userData.facebook_url,
            this.form.linkedin_url =  userData.linkedin_url,
            this.form.twitter_url =  userData.twitter_url,
            this.form.instagram_url =  userData.instagram_url,
            this.form.tiktok_url =  userData.tiktok_url,
            this.form.youtube_url =  userData.youtube_url,
            this.form.about_you =  userData.about_you,

           
            this.form.agency_name = userServiceData.agency_name,
            this.form.agency_license = userServiceData.agency_license,
            this.form.agency_registration_no = userServiceData.agency_registration_no,
            this.form.position_held = userServiceData.position_held,
            this.form.service_type_id = userServiceData.service_type_id ? userServiceData.service_type_id : 0,
            this.form.company_name = userServiceData.company_name,
            this.form.availability_days = userServiceData.availability_days,
            this.form.is_available_mon = userServiceData.is_available_mon == 1 ? true :false,
            this.form.is_available_tue = userServiceData.is_available_tue == 1 ? true :false,
            this.form.is_available_wed = userServiceData.is_available_wed == 1 ? true :false,
            this.form.is_available_thu = userServiceData.is_available_thu == 1 ? true :false,
            this.form.is_available_fri = userServiceData.is_available_fri == 1 ? true :false,
            this.form.is_available_sat = userServiceData.is_available_sat == 1 ? true :false,
            this.form.is_available_sun = userServiceData.is_available_sun == 1 ? true :false,
            this.form.working_hours = userServiceData.working_hours,
            this.form.show_as_agent = userServiceData.show_as_agent  == 1 ? true  :  false,
            this.form.personal_service_type_id = userServiceData.personal_service_type_id ? userServiceData.personal_service_type_id : 0,
            this.form.personal_working_since = userServiceData.personal_working_since ? userServiceData.personal_working_since : '',
           

            // This is not executed, as some error has thrown before
            await this.$store.dispatch('profileUpdate', response.data.output);
        } catch (error) {
          console.error('Failed to fetch profile:', error);
        }

      } else {
        console.log('User is not authenticated');
      }
      // If Profile data
    }
  }
};
</script>

<style scoped>
.url-container {
	margin: 10px 0px;
	width: 100%;
}

.url-box {
  border: 1px solid #ddd;
  padding: 7px 7px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ddd;
  text-transform: lowercase;
}

.copy-success {
  color: green;
  margin-top: 10px;
}
</style>
