<template>
  <div class="m-5">
    <section class="custom-listing">
      <div class="container">
        <div class="filter-bar-se">
          <div class="comman-title mb-0">
            <h3>Most Viewed</h3>
          </div>
          <div class="filter-right-bar">
            <div class="dropdown">
              <a href="/filter" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                <img src="frontend/images/filter-ico.svg"> Sort By
              </a>
              <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                <li><a class="dropdown-item" href="/newest" @click.prevent="sortProperties('Newest')">Newest</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

<!--    <section v-if="response.latest_properties && response.latest_properties.length > 0" class="recnet-list-sec">-->
<!--      <div class="container">-->
<!--        <div class="comman-title d-md-flex align-items-center justify-content-between">-->
<!--          <div class="offer-heading">-->
<!--            <span>Browse more offers</span>-->
<!--            <h3>{{ getSectionName('latest_properties') }}</h3>-->
<!--          </div>-->
<!--          <div class="readmore ms-md-auto">-->
<!--            <a @click.prevent="redirectToExploreMore">-->
<!--              Explore more-->
<!--              <i><img src="frontend/images/rgt-arrow.svg" alt="Right arrow" /></i>-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2">-->
<!--          <div class="col" v-for="item in response.latest_properties.slice(0,4)" :key="item.id">-->
<!--            <PropertyBox :item="item"></PropertyBox>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->


  </div>


</template>

<script>


export default {
  name: 'MostViewed',
  // components: {PropertyBox},

};
</script>

<style>

</style>