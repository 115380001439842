<template>    
    
	<section class="w-2-property-lsi">
		<div class="container">
			<div class="progress">
				<div class="progress-bar" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
			<div class="step-container d-flex justify-content-between">
				<div class="step-circle"><span>1</span> Step 1</div>
				<div class="step-circle"><span>2</span> Step 2</div>
				<div class="step-circle"><span>3</span> Step 3</div>
			</div>
			
			<form @submit.prevent="submitPost" id="multi-step-form">
				<div class="step step-1">										
					<div class="form-group">
						<label>Listing Type</label>
						<select 
							id="group1Select" 
							class="form-control"
							v-model="form.listingType" 
						>
						<option value="" selected>Listing Type</option>
							<option 
							v-for="option in optionsGroup1" 
							:key="option.value" 
							:value="option.value"
							>
							{{ option.label }}
							</option>
						</select>
						<span  class="error" v-if="errors.listingType">{{ errors.listingType }}</span>
					</div>					
					<div class="form-group">
						<label>Property Type</label>
						<select class="form-control" v-model="form.selectedPropertyType">
							<option value="" disabled>Select Property Type</option>
							<RecursiveDropdown :options="hierarchicalOptions" />
						</select>
						<span  class="error" v-if="errors.selectedPropertyType">{{ errors.selectedPropertyType }}</span>
					</div>					
					<div class="form-group">
						<label>Property/Project Name</label>
						<input type="text" class="form-control" v-model="form.property_name" placeholder="Property/Place/Project Name">
                        <span  class="error" v-if="errors.property_name">{{ errors.property_name }}</span>
					</div>
					<div class="form-group">
						<label>Street</label>
						<input
						class="form-control"
						type="text"
						v-model="form.street"
						@input="fetchSuggestions"
						placeholder="Type to Street..."
						/>
						<span  class="error" v-if="errors.street">{{ errors.street }}</span>
						<ul v-if="suggestions.length" class="sugesstion_ul">
							<li v-for="(suggestion, index) in suggestions" :key="index" @click="selectSuggestion(suggestion)">
								{{ suggestion }}
							</li>
						</ul>
					</div>
					<div class="form-group">
						<label>Area / HDB Estate</label>
						<input
						class="form-control"
						type="text"
						v-model="form.area_hdb_estate"
						@input="fetchSuggestionsArea"
						placeholder="Type to Area..."
						/>
						<span  class="error" v-if="errors.area_hdb_estate">{{ errors.area_hdb_estate }}</span>
						<ul v-if="suggestionsArea.length" class="sugesstion_ul">
							<li v-for="(suggestion, index) in suggestionsArea" :key="index" @click="selectSuggestionArea(suggestion)">
								{{ suggestion }}
							</li>
						</ul>
						<span  class="error" v-if="errors.area_hdb_estate">{{ errors.area_hdb_estate }}</span>
					</div>
					<div class="form-group">
						<label>District</label>
						<input type="hidden" class="form-control"  v-model="form.district" id="district" placeholder="District Name">
						<select id="district_id" v-model="form.district_id" class="form-control" @change="changeDistrict($event)">
							<option value="" disabled selected>Select District</option>
							<option v-for="option in optionsDistrictList" :key="option.value" :value="option.value">
								{{ option.label }}
							</option>
						</select>
						<span  class="error" v-if="errors.district">{{ errors.district }}</span>
					</div>
					<div class="form-group">
						<label>Floor Area (SQF)</label>
						<input type="number" min="0"  class="form-control col" v-model="form.floor_area" placeholder="Floor Area">
                        <span  class="error" v-if="errors.floor_area">{{ errors.floor_area }}</span>
					</div>
					<div class="form-group">
						<label>No. Of Baths</label>
						<input type="number" min="0" class="form-control"   v-model="form.no_of_baths"  placeholder="Baths">	
                        <span  class="error" v-if="errors.no_of_baths">{{ errors.no_of_baths }}</span>	 
					</div>
					<div class="form-group">
						<label>No. Of Rooms</label>
						<input type="number" min="0" class="form-control"   v-model="form.no_of_rooms"  placeholder="Rooms">											 
                        <span  class="error" v-if="errors.no_of_rooms">{{ errors.no_of_rooms }}</span>
					</div>
					<div class="form-group">
						<label>Furnishing & Fittings</label>
						<select id="furnishing_fitting_id" v-model="form.furnishing_fitting_id" class="form-control" @change="changeDistrict($event)">
							<option value="" disabled selected>Select Furnishing & Fittings</option>
							<option v-for="option in checkboxFFlist" :key="option.id" :value="option.id">
								{{ option.name }}
							</option>
						</select>
						<span  class="error" v-if="errors.furnishing_fitting_id">{{ errors.furnishing_fitting_id }}</span>
					</div>
					<div class="pull-right">
						<button type="submit" class="comman-btn-2 next-step">Next</button>
					</div>
				</div>
			</form>
		</div>
	</section>

</template>
  
<script> 
  import RecursiveDropdown from '@/components/RecursiveDropdown.vue';
  import { mapState } from 'vuex';
  import { OPTIONS_GROUP_1,PROPERTY_TYPE } from '@/constants/constantsProperty';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';
  import { CONSTANT } from '@/constants/constants';
  export default {
    name: 'Widget1Page', 
    data() {
        return {
			optionsGroup1: OPTIONS_GROUP_1,
			suggestions: [],
            suggestionsArea: [],
            optionsDistrictList: [],
            checkboxFFlist: [],
            editData: '',
			form: {
                listingType: '',
                district_id: '',
                furnishing_fitting_id: '',
                selectedPropertyType: '',
            },
			options: this.transformData(PROPERTY_TYPE),
			errors: {}
        }
    },
	components: {
      RecursiveDropdown
    }, 
	created() {
        this.fetchOptions();
	},
	computed: {
        ...mapState(['isAuthenticated', 'user']),
		hierarchicalOptions() {        
			return this.createHierarchicalOptions(this.options);
		},
        id() {
			return this.$route.params.id;
		},
		idType() {
			return this.$route.params.id_type;
		},   
    },
	methods: {
		async fetchOptions() {
			// Get District list
			try {
				const response = await this.$axios.post('api/location',
				{                    
					type : "City",
					keyword : this.street, 
					parent_id: CONSTANT.COUNTRY_CODE                   
				});
				this.optionsDistrictList = response.data.output.map(item => ({
					value: item.id,
					label: item.name
				}));
			} catch (error) {
				console.error('Error fetching options:', error);
			}

			// Furnishing & Fittings
            try {
                const response = await this.$axios.post('api/pr-master-furnishing-fittings-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_checkbox: 1,
                    show_in_property:1,
                    show_in_included : 0,                     
                    last_record_number : 0                     
                });
                this.checkboxFFlist = response.data.output.map(item => ({
                    id: item.id,
                    name: item.name
                }));
                console.log(this.checkboxFFlist);
            } catch (error) {
                console.error('Error fetching options:', error);
            }
		},
        validateForm() {
            this.errors = {};
            if (!this.form.listingType ) {
                this.errors.listingType = 'Listing Type is required.';
            }
            if (!this.form.property_name ) {
                this.errors.property_name = 'Property Name is required.';
            }
            if (!this.form.street ) {
                this.errors.street = 'Street is required.';
            }
            if (!this.form.area_hdb_estate ) {
                this.errors.area_hdb_estate = 'Area / HDB Estate.';
            }
            if (!this.form.district_id ) {
                this.errors.district_id = 'District is required.';
            }
            if (!this.form.district ) {
                this.errors.district = 'District is required.';
            }           
            if (!this.form.floor_area ) {
                this.errors.floor_area = 'Floor Area is required.';
            }
            if (!this.form.no_of_baths ) {
                this.errors.no_of_baths = 'Baths is required.';
            }
            if (!this.form.no_of_rooms ) {
                this.errors.no_of_rooms = 'Rooms is required.';
            }
            if (!this.form.furnishing_fitting_id ) {
                this.errors.furnishing_fitting_id = 'Furnishing & Fittings is required.';
            }  
			if (!this.form.selectedPropertyType ) {
                this.errors.selectedPropertyType = 'Property type is required.';
            }         
            return Object.keys(this.errors).length === 0;
        },        
        async submitPost() {
            
            if (this.validateForm()) {
                
                const token = localStorage.getItem('userToken');
                if (this.loading) return;
                this.loading = true;
                try {
                    var loginted_user_id = 0;
                    if(this.user.id > 0){
                        loginted_user_id = this.user.id;
                    }
                    const response = await this.$axios.post('api/add-widget-property', {
                        user_id :loginted_user_id,
                        api_token: token,
                        property_listing_id: 0,
						property_name:this.form.property_name,
						property_type:this.form.selectedPropertyType,
						street:this.form.street,
                        area_hdb_estate:this.form.area_hdb_estate,
                        district_id:this.form.district_id,
						floor_area:this.form.floor_area,
						no_of_baths:this.form.no_of_baths,
						no_of_rooms:this.form.no_of_rooms,
						furnishing_fitting_id:this.form.furnishing_fitting_id,
						referral_user_id:this.id,
						referral_user_type:this.idType
                    });
                    
                    toastr.success(response.data.message, 'Success', { timeOut: 3000 });

                    var property_listing_id = response.data.output.id
                    this.$router.push('/widget-property-post-2/'+this.id+'/'+this.idType+'/'+property_listing_id);         

                } catch (error) {
                    
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
		async fetchSuggestions() {
            if (this.form.street.length < 2) {
                this.suggestions = []; // Clear suggestions if query is too short
                return;
            }
            try {
                const response = await this.$axios.post('api/location',
                {                    
                    type : "Locality",
                    keyword : this.street, 
                    parent_id: CONSTANT.COUNTRY_CODE
                });
                this.suggestions = response.data.output.map(item => item.name).filter(name => name.toLowerCase().includes(this.form.street.toLowerCase()));

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        },
        
        async fetchSuggestionsArea() {
            if (this.form.area_hdb_estate.length < 2) {
                this.suggestionsArea = []; // Clear suggestions if query is too short
                return;
            }
            try {
                const response = await this.$axios.post('api/location',
                {                    
                    type : "Locality",
                    keyword : this.area_hdb_estate, 
                    parent_id: CONSTANT.COUNTRY_CODE
                });
                this.suggestionsArea = response.data.output.map(item => item.name).filter(name => name.toLowerCase().includes(this.form.area_hdb_estate.toLowerCase()));

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        },
        
        selectSuggestion(suggestion) {
            this.form.street = suggestion;
            this.suggestions = [];
        },
        selectSuggestionArea(suggestion) {
            this.form.area_hdb_estate = suggestion;
            this.suggestionsArea = [];
        },
        selectSuggestionDistrict(suggestion) {
            
            this.form.district = suggestion.label;
            this.form.district_id = suggestion.value;
            this.suggestionsDistrict = [];
        },
        changeDistrict(){
            
            const selectedValue = this.form.district_id; // Get the value from v-model
            const selectedOption = this.optionsDistrictList.find(option => option.value === selectedValue);
            
            if (selectedOption) {
                const selectedLabel = selectedOption.label;
                // console.log('Selected Value:', selectedValue);
                // console.log('Selected Label:', selectedLabel);
                this.form.district = selectedLabel;
                // You can now use selectedValue and selectedLabel as needed
            }
            
        },
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },
		transformData(data) {
            const map = {};
            const result = [];
    
            data.forEach(item => {
            map[item.id] = { ...item, children: [] };
            });
    
            data.forEach(item => {
            if (item.parent_id === 0) {
                result.push(map[item.id]);
            } else {
                map[item.parent_id].children.push(map[item.id]);
            }
            });
    
            return result;
        },
		createHierarchicalOptions(data) {
            return data.map(option => this.createOptionGroup(option));
        },
        createOptionGroup(option) {
            if (!option.children || option.children.length === 0) {
                return {
                    type: 'option',
                    id: option.id,
                    label: option.name,
                    depth: option.depth,
                };
            }
            return {
                type: 'optgroup',
                label: option.name,
                depth: option.depth,
                children: option.children.map(child => this.createOptionGroup(child))
            };
        },

	}
  }
</script>
  
<style scoped>
	.sugesstion_ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        border: 1px solid #ddd;
        position: absolute;
        background: #fff;
    }
    .sugesstion_ul li {
        cursor: pointer;
        padding: 8px;
    }
    .sugesstion_ul li:hover {
        background-color: #f0f0f0;
    }
</style>
  