<template>
  <section class="pricing-se">
    <div class="container-xl">
      <div class="comman-title text-center">
        <h2>Price & Package Plan</h2>
      </div>
      <div class="row">
        <div v-if="packages.length === 0">
          No records found.
        </div>
        <div v-else class="col-md-4" v-for="packageItem in packages" :key="packageItem.id">
          <div class="pricing-box">
            <div class="badge-po-re" v-if="packageItem.highlight_text && packageItem.highlight_text.length > 0">{{ packageItem.highlight_text }}</div>
            <h3>{{ packageItem.name }} {{ packageItem.second_title }}</h3>
            <p>{{ packageItem.sub_title }}</p>
            <div class="price">
              S${{ parseFloat(packageItem.annual_subscription).toFixed(packageItem.annual_subscription % 1 !== 0 ? 2 : 0) }}
            </div>
            <ul v-if="filteredPackageFeatures(packageItem, packageFeatures).length > 0">
              <li v-for="feature in filteredPackageFeatures(packageItem, packageFeatures)" :key="feature.id">
                {{ feature.titleToShow }}
                <ul v-if="filteredPackageFeatures(packageItem, feature.innerItems).length > 0">
                  <li v-for="innerFeature in filteredPackageFeatures(packageItem, feature.innerItems)" :key="innerFeature.id">
                    • {{ innerFeature.titleToShow }}
                  </li>
                </ul>
              </li>
            </ul>
            <div class="readmore mt-4 mb-3" data-bs-toggle="modal" data-bs-target="#pricing-detail">
              <a href="javascript:;" style="font-size: 14px;">View More <i class="fa fa-arrow-right"></i></a>
            </div>
            <a
                href="#"
                class="comman-btn-2"
                @click.prevent="choosePackage(packageItem)"
            >
              Choose Package
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Pricing Popup Start -->
  <div class="modal fade otp" id="pricing-detail" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>
        <div class="modal-body">
          <div class="pricing-table-box">
            <div class="table-responsive">
              <table class="table text-center table-borderless align-middle">
                <thead>
                <tr>
                  <th></th>
                  <th colspan="2">Private</th>
                  <th colspan="3">Business</th>
                  <th colspan="2">Corporate</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="allFeatures.length === 0" rowspan="8">
                  No records found.
                </tr>
                <tr v-else v-for="(feature, featureIndex) in allFeatures" :key="featureIndex">
                  <template v-if="feature.isParentFeature">
                    <td class="text-start bg-light" colspan="8">
                      {{ feature.feature }}
                    </td>
                  </template>
                  <template v-else>
                    <td v-if="featureIndex === 0" class="text-start">
                      <h3>{{ feature.feature }}</h3>
                    </td>
                    <td v-else class="text-start">
                      {{ feature.isInnerItem ? '• ' : '' }}{{ feature.feature }}
                    </td>
                    <td
                        v-for="(packValue, packIndex) in feature.packages"
                        :key="packIndex"
                    >
                      {{ packValue === 0 ? 'N' : packValue === 1 ? 'Y' : packValue === 2 ? 'N/A' : packValue }}
                    </td>
                  </template>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Pricing Popup End -->
</template>
<style type="text/css">
.pricing-table-box table tr td {
  font-weight: normal !important;
}
</style>
<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      packages: [],
      packageFeatures: [],
      allFeatures: [],
    };
  },
  computed: {
    ...mapState(['isAuthenticated', 'user']),
  },
  async created() {
    await this.fetchPackages();
    await this.fetchPackageFeatures();
    await this.mapPackageData();
  },
  methods: {
    async fetchPackages() {
      try {
        const packagesResponse = await this.$axios.post('/api/package-list', {
          api_token: localStorage.getItem('userToken'),
          is_private: !this.user.is_business && !this.user.is_corporate,
          is_business: !this.user.is_corporate,
          is_corporate: 1,
          show_in_moreinfo_screen: 0,
          show_in_price_page: 1,
        });

        this.packages = packagesResponse.data.output;
      } catch (error) {
        console.error('Error fetching packages:', error);
      }
    },
    async fetchPackageFeatures() {
      try {
        const packageFeatureResponse = await this.$axios.post('/api/package-feature-list', {
          api_token: localStorage.getItem('userToken'),
          is_private: 1,
          is_business: 1,
          is_corporate: 1,
          show_in_moreinfo_screen: 0,
          show_in_price_page: 1,
        });

        this.packageFeatures = packageFeatureResponse.data.output;
      } catch (error) {
        console.error('Error fetching package features:', error);
      }
    },
    filteredPackageFeatures(packageItem, features) {
      return features.filter(feature => packageItem[feature.field_name_in_PAF_product] === 1);
    },
    async mapPackageData() {
      const packagesResponse = await this.$axios.post('/api/package-list', {
        api_token: localStorage.getItem('userToken'),
        is_private: 1,
        is_business: 1,
        is_corporate: 1,
        show_in_moreinfo_screen: 1,
        show_in_price_page: 0,
      });

      const packages = packagesResponse.data.output;

      const featuresResponse = await this.$axios.post('/api/package-feature-list', {
        api_token: localStorage.getItem('userToken'),
        is_private: 1,
        is_business: 1,
        is_corporate: 1,
        show_in_moreinfo_screen: 1,
        show_in_price_page: 0,
      });

      const features = featuresResponse.data.output;

      this.allFeatures.push({
        feature: 'Service Offered',
        packages: packages.map(packageItem => packageItem.name),
        isParentFeature: false,
        isInnerItem: false,
      });

      for (const feature of features) {
        this.allFeatures.push({
          feature: feature.titleToShow,
          packages: packages.map((packageItem) => {
            return packageItem[feature.field_name_in_PAF_product] || null;
          }),
          isParentFeature: feature.innerItems.length,
          isInnerItem: false,
        });

        if (feature.innerItems && feature.innerItems.length > 0) {
          for (const innerFeature of feature.innerItems) {
            this.allFeatures.push({
              feature: innerFeature.titleToShow,
              packages: packages.map((packageItem) => {
                return packageItem[innerFeature.field_name_in_PAF_product] || null;
              }),
              isParentFeature: false,
              isInnerItem: true,
            });
          }
        }
      }
    },
    async choosePackage(packageItem) {
      try {
        const packageOrderResponse = await this.$axios.post('api/user_PAF_product_order_add', {
          api_token: localStorage.getItem('userToken'),
          user_id: this.user.id,
          paf_product_id: packageItem.id,
          annual_subscription: packageItem.annual_subscription,
          promotional_rebate: packageItem.promotional_rebate,
          current_annual_subscription: packageItem.current_annual_subscription
        });

        const packageOrder = packageOrderResponse.data;

        if (packageOrder.status) {
          this.redirectToPayment(packageItem.id);
        } else {
          console.error('Error while creating package order.');
        }
      } catch (error) {
        console.error('Error while creating package order:', error);
      }
    },
    redirectToPayment(packageId) {
      this.$router.push({
        name: 'Payments',
        query: { packageId },
      });
    },
    isFloat(x) {
      return !!(x % 1);
    },
  }
};
</script>
