<template>
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-lg-10 col-md-12 rounded p-4" style="background:rgb(250, 246, 238)">
        <div class="row">

          <div class="col-md-6 align-content-center text-black p-4 border-md-right-2">
            <div class="p-5">
              <h3 class="mb-3">Follow Us On Social Media</h3>
              <p>We're here to assist you! If you have any questions or need assistance, please feel free to reach out to us.</p>
              <div class="d-flex justify-content-start gap-3">
                <a href="https://www.x.com/login" target="_blank"><img src="/frontend/images/tweet-ico.svg" alt="x.com"></a>
                <a href="https://www.facebook.com/login" target="_blank"><img src="/frontend/images/fb-ico.svg" alt="facebook"></a>
                <a href="https://www.linkedin.com/login" target="_blank"><img src="/frontend/images/linkedin-ico.svg" alt="linkedin"></a>
                <a href="https://www.youtube.com/accounts/login" target="_blank"><img src="/frontend/images/youtube-ico.svg" alt="youtube"></a>
                <a href="https://www.tiktok.com/login" target="_blank"><img src="/frontend/images/tiktok-icon.svg" alt="tiktok"></a>
              </div>
            </div>
          </div>

          <div class="col-md-6 p-4">
            <h2 class="text-center mb-4">Contact Us</h2>
            <form>
              <div class="mb-3">
                <input type="text" class="form-control" placeholder="Your Name" required>
              </div>
              <div class="mb-3">
                <input type="email" class="form-control" placeholder="Email Address" required>
              </div>
              <div class="mb-3">
                <input type="tel" class="form-control" placeholder="Phone No." required>
              </div>
              <div class="mb-3">
                <input type="text" class="form-control" placeholder="Company / Organisation">
              </div>
              <div class="mb-3">
                <select class="form-control" required>
                  <option value="" disabled selected>Subject</option>
                  <option value="General Inquiry">General Inquiry</option>
                  <option value="Support">Support</option>
                </select>
              </div>
              <div class="mb-3">
                <textarea class="form-control" v-model="message" placeholder="Type Your Message Here" rows="4" maxlength="100"></textarea>
                <small class="form-text text-muted">
                  {{ remainingChars }} characters remaining
                </small>
              </div>
              <div class="form-check mb-3">
                <input type="checkbox" class="form-check-input" id="save-info">
                <label class="form-check-label fw-bold" for="save-info">Save This Information For Next Time</label>
              </div>

              <div class="text-center">
                <button type="submit" class="btn btn-warning text-dark fw-bold w-100">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ContactUs',
  data() {
    return {
      message: '',
    };
  },
  computed: {
    remainingChars() {
      return 100 - this.message.length;
    }
  }
};
</script>

<style>

</style>