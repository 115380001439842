<template>
  <section class="hero-banner-sec">
    <div class="image-container">
      <img src="/frontend/images/about-2.jpg" alt="Real Estate">
      <div class="overlay"></div>
      <div class="hero-text">
        <h1>Your Premier Destination For Real Estate Solutions</h1>
        <p>At <b>PROP</b>FACE, We Believe That Real Estate Is More Than Just Buying And Selling Properties - It's About Turning Dreams Into Reality</p>
        <div class="btn-container">
          <a class="comman-btn-2" href="/feature" >Learn More</a>
        </div>
      </div>
    </div>
  </section>

  <section class="about-section mt-5 py-5">
    <div class="container mt-3">
      <div class="row">
        <div class="col-md-6">
            <img style="border-radius: 20px" src="/frontend/images/about-1.jpg" alt="About Us">
        </div>
        <div class="col-md-6 align-content-center">
          <div class="text-content">
            <h4 class="mt-3 mb-4">About Us</h4>
            <p>
              Welcome to <b>PROP</b>FACE, a boutique proptech outfit dedicated to delivering quality
              innovation in the local and regional real estate market. We pride ourselves on pioneering
              many firsts in the industry, incorporating unique features like <b>Hybrid-Listing</b>, <b>Auto-Sourcing
              Widget</b> and <b>FengShui Map</b> to enhance both your property listing or searching experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="vision-section">
    <div class="container mt-3">
      <div class="row">
        <div class="col-md-6 align-content-center">
          <div class="text-content">
            <p>
              <b>PROP</b>FACE is not a portal that makes you pay to compete for mere search rankings visibility.
              It provides fair opportunities for users to present their own properties beyond listings,
              where every property tells a unique story, the true features that unlock its hidden-value. Our
              platform is designed for properties to transact, facilitates successful deals while navigating
              through the imperfect market conditions.
            </p>
          </div>
        </div>
        <div class="col-md-6 px-4 pe-0">
            <img class="" style="border-radius: 20px" src="/frontend/images/about-2.jpg" alt="Vision">
        </div>
      </div>
    </div>
  </section>

  <section class="mission-section">
    <div class="container mt-3">
      <div class="row">
        <div class="col-md-6 px-4 ps-0">
            <img style="border-radius: 20px" src="/frontend/images/about-3.jpg" alt="Mission">
        </div>
        <div class="col-md-6 align-content-center">
          <div class="text-content">
            <p>
              At <b>PROP</b>FACEFACE, we blend advanced technology ideas with dynamic platform economics. Our
              <b>AI Model</b> generates creative content-services and autonomous predictive-responses to
              provide a seamless, intuitive user experience, ensuring that you have access to the latest
              trends with relevant information. Our mission is to enhance and deliver the experience of
              buying, selling, and renting properties by connecting property owners, buyers, tenants, and
              the real estate industry stakeholders in unique ways.
            </p>
            <p>
              Join our community and experience the difference where technology meets innovation,
              explore beyond property listings at <b>PROP</b>FACE – Your Place, Your Space!
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section v-if="response.stats && response.stats.length > 0" class="home-counter-sec mt-5 ">
    <div class="container">
      <ul>
        <li v-for="(item, index) in response.stats" :key="index">
          <h3>{{ item.count_val }}</h3>
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
  </section>

  <section v-if="response.agent_details && response.agent_details.length > 0" class="meet-agent-sec">
    <div class="container">
      <div class="comman-title text-center">
        <span>Our registered agents</span>
        <h3>Meet our agents</h3>
      </div>
      <div class="row">
        <div v-for="(agent, index) in response.agent_details" :key="index" class="col-md-4">
          <div>
            <div class="meet-agent-bx">
              <div class="meet-pic">
                <picture>
                  <img :src="getAgentImage(agent.profile_photo_name)">
                </picture>
                <div class="mark-logo">
                  <img src="frontend/images/logo.png">
                </div>
              </div>
              <div class="agent-info">
                <h3>{{ agent.name }}</h3>

                <span>{{ agent.user_service_details.service_type_id.name }}</span>
                <p v-if="agent.about_you">{{ agent.about_you }}</p>
              </div>
              <div class="agent-profile">
                <a v-bind:href="'/agents/' +  agent.id " class="comman-btn-2">View Profile</a>

                <div class="profile-call ms-md-auto">
                  <a :href="'tel:' + agent.contact_phone">
                    <img src="frontend/images/phone-ico.svg">
                  </a>
                  <a :href="'mailto:'+ agent.contact_email">
                    <img src="frontend/images/email-ico.svg">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="testimonial-sec">
    <div class="container">
      <div class="comman-title text-center">
        <span>Testimonials</span>
        <h3>what our customer say about us</h3>
      </div>
      <div class="testimonail-carousel owl-carousel owl-theme">
        <div class="item">
          <div class="testimoanil-bx">
            <div class="client-header">
              <figure>
                <img src="frontend/images/agent1.png">
              </figure>
              <div class="client-nm">
                <h4>Jane Cooper</h4>
                <div class="rating-show">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
              </div>
            </div>
            <div class="testimoanil-content">
              <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="testimoanil-bx">
            <div class="client-header">
              <figure>
                <img src="frontend/images/agent2.jpg">
              </figure>
              <div class="client-nm">
                <h4>Ralph Edwards</h4>
                <div class="rating-show">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
              </div>
            </div>
            <div class="testimoanil-content">
              <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="testimoanil-bx">
            <div class="client-header">
              <figure>
                <img src="frontend/images/agent3.png">
              </figure>
              <div class="client-nm">
                <h4>Brooklyn Simmons</h4>
                <div class="rating-show">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
              </div>
            </div>
            <div class="testimoanil-content">
              <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="testimoanil-bx">
            <div class="client-header">
              <figure>
                <img src="frontend/images/agent1.png">
              </figure>
              <div class="client-nm">
                <h4>Jane Cooper</h4>
                <div class="rating-show">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
              </div>
            </div>
            <div class="testimoanil-content">
              <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="testimoanil-bx">
            <div class="client-header">
              <figure>
                <img src="frontend/images/agent2.jpg">
              </figure>
              <div class="client-nm">
                <h4>Ralph Edwards</h4>
                <div class="rating-show">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
              </div>
            </div>
            <div class="testimoanil-content">
              <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="testimoanil-bx">
            <div class="client-header">
              <figure>
                <img src="frontend/images/agent3.png">
              </figure>
              <div class="client-nm">
                <h4>Brooklyn Simmons</h4>
                <div class="rating-show">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
              </div>
            </div>
            <div class="testimoanil-content">
              <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import {CONSTANT} from "@/constants/constants";

// <section className="container">
//   <div className="grid">
//     <div className="card">
//       <div className="card-icon mb-2">
//         <img src="/frontend/images/icon-box-icon.svg" alt="house-icon"/>
//       </div>
//       <div className="card-title fw-bold">Sell With Confidence</div>
//       <div className="card-description">
//         At DreamSpace Realty, we believe that real estate is more than just buying and selling properties—it's about
//         turning dreams into reality.
//       </div>
//     </div>
//     <div className="card">
//       <div className="card-icon mb-2">
//         <img src="/frontend/images/icon-box-icon.svg" alt="house-icon"/>
//       </div>
//       <div className="card-title fw-bold ">Sell With Confidence</div>
//       <div className="card-description">
//         At DreamSpace Realty, we believe that real estate is more than just buying and selling properties—it's about
//         turning dreams into reality.
//       </div>
//     </div>
//     <div className="card">
//       <div className="card-icon mb-2">
//         <img src="/frontend/images/icon-box-icon.svg" alt="house-icon"/>
//       </div>
//       <div className="card-title fw-bold ">Sell With Confidence</div>
//       <div className="card-description">
//         At DreamSpace Realty, we believe that real estate is more than just buying and selling properties—it's about
//         turning dreams into reality.
//       </div>
//     </div>
//     <div className="card">
//       <div className="card-icon mb-2">
//         <img src="/frontend/images/icon-box-icon.svg" alt="house-icon"/>
//       </div>
//       <div className="card-title fw-bold ">Sell With Confidence</div>
//       <div className="card-description">
//         At DreamSpace Realty, we believe that real estate is more than just buying and selling properties—it's about
//         turning dreams into reality.
//       </div>
//     </div>
//   </div>
// </section>

export default {
  name: 'AboutUs',

  data() {
    return {
      response: {},
      SectionTitleMap: {
        locations_details: 'Explore by Location',
        stats: 'Status',
        agent_details: 'Meet our agents'
      }
    };
  },

  mounted() {
    this.$axios.post('/api/home-page-details', {
      user_id: 50,
      country_id: 93,
      location_type_id: 0,
      property_type_id: 0
    }).then((response) => {
      this.response = response.data.output;
    }).catch((error) => {
      console.log(error);
    });

    window.$('.testimonail-carousel').owlCarousel({
      loop: false,
      margin: 25,
      nav: false,
      dots: true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 3
        }
      }
    });
  }
  ,

  methods: {
    getSectionName(apiArrayName) {
      return this.SectionTitleMap[apiArrayName] || null;
    },
    getAgentImage(path) {
      return path ? `${CONSTANT.IMAGE_URL}/imagesProfile/${path}` : CONSTANT.DEFAULT_NO_IMAGE;
    }
    ,
  }
};
</script>

<style scoped>

.hero-banner-sec {
  position: relative;
  width: 98%;
  height: 800px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Dark overlay with 40% opacity */
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1;
  max-width: 60%; /* Control the width of text */
}

.hero-text h1 {
  font-size: 72px; /* Adjust font size */
  margin-bottom: 10px;
}

.hero-text p {
  font-size: 18px; /* Adjust font size */
  color: white;
  margin-bottom: 20px;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-content p {
  line-height: 1.8em;
}

.grid {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  padding: 40px 0;
}

.card {
  background-color: #f7f8fa;
  padding: 20px;
  border-radius: 40px;
  border: none;
  text-align: start;
  max-width: 250px;
  flex: 1;
}

.card-icon {
  height: 50px;
  width: 50px;
  color: #f39c12;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-description {
  font-size: 14px;
}
</style>
