<template>
  <div class="container">
    <h1 class="my-5">Frequently Asked Question</h1>
    <h3>1. What makes PROPFACE unique compared to other property listing portals?</h3>
    <p>At PROPFACE, we deliver many “first-in-market” features designed to enhance your property listing experience:</p>
    <ol type="a">
      <li>
        <p><span class="fw-bold">Hybrid-Listing:</span> Owners are empowered with control and flexibility to craft property contents themselves, or track assignment to agents with transparency via digital terms.</p>
      </li>
      <li>
        <p><span class="fw-bold">FengShui Map:</span> Personalized searches using birthdates to match buyers or tenants, unlocking hidden-values of each property, and adding uniqueness to combat generic listings.</p>
      </li>
      <li>
        <p><span class="fw-bold">Property Widget + Referral Code:</span> Agents can source for listings automatically 24/7, reach wider market and track direct owner listings efficiently.</p>
      </li>
      <li>
        <p><span class="fw-bold">Artificial Intelligence-Generative:</span> Engaging property narratives, creating your own story with reviews, analytics, and autonomous response for social media post in 36 types of languages.</p>
      </li>
      <li>
        <p><span class="fw-bold">Alternative Insights:</span> Property reviews, comparisons, and unique features, analyse surroundings, location accessibility, or new or up-coming project launches, community vibes, and market trends that answer some of the questions like why prices are fluctuating!</p>
      </li>
      <li>
        <p><span class="fw-bold">Digital Co-Broking:</span> Facilitates commission-sharing in co-broking arrangements among estate agents within Singapore, or between Malaysia in collaborative cross-border property listings.</p>
      </li>
    </ol>
    <h3 class="mt-4">2. How do I sign-up for PROPFACE?</h3>
    <p>Signing up is simple and straightforward:</p>
    <ol type="a">
      <li>
        <p>Visit our website at www.propface.sg.</p></li>
      <li>
        <p>Click on the <span class="fw-bold">Sign-Up</span> button on the top-right.</p>
      </li>
      <li>
        <p>Choose your <span class="fw-bold">User Type</span> (Property Owner, Buyer or Renter, Estate Agent, Property Agency, or Developer).</p>
      </li>
      <li>
        <p>Fill in your details such as Name, EMail, and Mobile Contact No.</p>
      </li>
      <li>
        <p>Go to your EMail and click on the activation link.</p>
      </li>
      <li>
        <p>Log-in to your profile, request for One-Time-Pin (OTP) and verify your Mobile Contact No. to start creating property listing.</p>
      </li>
      <li>
        <p>Perform optional SingPass or CorpPass verification to enhance credibility and establish genuine enquiries with <span class="fw-bold">Verified Badge</span> for your profile.</p>
      </li>
    </ol>
    <h3 class="mt-4">3. How do I get my property listing verified?</h3>
    <p>For a verified property listing status:</p>
    <ol type="a">
      <li>
        <p>Create your property listing with complete and accurate information.</p></li>
      <li>
        <p>Provide full address, and include actual pictures of the intended property unit.</p>
      </li>
      <li>
        <p>Upload relevant property verification documents (e.g., Property Title & Deeds, Sale & Purchase Agreement, Tax / Assessment Or Utility Bills, Registered Intent To Sell For HDB).</p>
      </li>
      <li>
        <p>Our team or your assigned estate agent will review to verify the information.</p>
      </li>
      <li>
        <p>Once verified, your property listing will be qualified with <span class="fw-bold">Authentic Badge</span>, increasing trust and garnering more interest from serious buyers.</p>
      </li>
    </ol>
    <h3 class="mt-4">4. How do I create a listing on PROPFACE?</h3>
    <p>Creating a property listing is easy:</p>
    <ol type="a">
      <li>
        <p>Log-in to your user account.</p></li>
      <li>
        <p>Navigate to the <span class="fw-bold">Add / Create Listing</span> section at the top-right.</p>
      </li>
      <li>
        <p>Fill in property details like location, property type, size, price, and other field information.</p>
      </li>
      <li>
        <p>Upload good-quality images of the actual unit, floor layout plans and video-links of property.</p>
      </li>
      <li>
        <p>Save to edit, or submit the property listing for approval.</p>
      </li>
      <li>
        <p>Select <span class="fw-bold"> AI-Generative</span> feature option to request creation of compelling contents, and qualify for posting to other social medias or autonomous responsive digital marketing channels.</p>
      </li>
    </ol>
    <h3 class="mt-4">5. How can I benefit from the Hybrid-Listing feature?</h3>
    <p>Example Scenario: You are a property owner wanting to sell your condominium. With the Hybrid-Listing feature:</p>
    <ol type="a">
      <li>
        <p>You may create your property listing with personalized descriptions and highlight unique details that are best known to yourself as the owner.</p></li>
      <li>
        <p>Our platform instantly enhances your listing, and makes it stand-out above 30% against properties in other portals that are either generic or lacking details.</p>
      </li>
      <li>
        <p>You may decide on your level of participation, own the property listing while attending enquiries personally to save on commission, publish or hide your contact number for privacy, flexible and in-control to track its status simultaneously.</p>
      </li>
      <li>
        <p>Alternatively, you may assign your property listing to an estate agent or agency, specifying the negotiated commission, exclusivity arrangement, and tracked duration of appointment.</p>
      </li>
      <li>
        <p>As the estate agent or agency, and property managers alike, you may send invites to clients and prospects to list their property directly through the Hybrid-Listing link i.e. Property Widget, functioning as an auto-sourcing tool for endless stream of leads from direct owners.</p>
      </li>
    </ol>
    <h3 class="mt-4">6. How does the Property Widget with your Referral Code works?</h3>
    <p>Example Scenario: As an estate agent, you want to expand your network without taking too much of your time:</p>
    <ol type="a">
      <li>
        <p>The Property Widget’s direct link (URL) with a unique Referral Code will be generated and displayed in your profile.</p>
      </li>
      <li>
        <p>You may copy and send this link to your contacts via EMails, Mobile Messenger Apps like WhatsApp or post it to Social Medias.</p>
      </li>
      <li>
        <p>When a contact or viewer creates property listing via your link, it is captured under your leads.</p>
      </li>
      <li>
        <p>And if they assign the property listing, clear terms are specified for you to manage it and earn the commission.</p>
      </li>
      <li>
        <p>The Hybrid-Listing feature automatically sources and tracks properties from direct owner listings easily, works 24/7 as an enabler for efficiency, productivity, and transparency.</p>
      </li>
    </ol>
    <h3 class="mt-4">7. What is the Feng Shui Map and how can it enhance my property listing?</h3>
    <p>Example Scenario: You are a buyer interested in properties with positive vibes that uniquely connects with your innate personality at deeper levels:</p>
    <ol type="a">
      <li>
        <p>The Feng Shui Map allows you to match properties with just your gender and birth-year.</p>
      </li>
      <li>
        <p>It highlights property listings that align with your personality and preferences.</p>
      </li>
      <li>
        <p>You may also filter property listings specifically with North-South orientation, known for positive energy flow.</p>
      </li>
      <li>
        <p>The Feng Shui Map is also useful for property owners or estate agents in presenting each property listing to be unique on its own, tailored to specific preferences and compatibilities, unlocking hidden value that matters to the owner, buyer, or tenant.</p>
      </li>
    </ol>
    <h3 class="mt-4">8. How do I purchase and utilise Credits?</h3>
    <p>Credits can be purchased and used to access premium features according to your subscription package:</p>
    <ol type="a">
      <li>
        <p>Log-in to your account and navigate to the <span class="fw-bold"> Credits</span> section.</p>
      </li>
      <li>
        <p>Select the amount of Credits you want to purchase and complete the transaction through the various payment options.</p>
      </li>
      <li>
        <p>Use your Credits to unlock features such as Publishing Concurrent Property Listings, Featuring Profile, Accepting Assigned Property Listings, Enhanced AI-Generated Contents, and more.</p>
      </li>
    </ol>
    <h3 class="mt-4">9. Do Credits expire?</h3>
    <p>Credits may or may not expire depending on your subscription package which is currently set at 12 months validity, check your package details for specific information on credit expiration.</p>
    <h3 class="mt-4">10. How does the Artificial Intelligence (AI) Creative Content service works?</h3>
    <p>Our AI model assists you by:</p>
    <ol type="a">
      <li>
        <p>Creating media contents based on details and information provided in your property listing.</p>
      </li>
      <li>
        <p>Generating social media posts with any of the 36 languages selection.</p>
      </li>
      <li>
        <p>Strategizing contents based on specified target audiences with premium feature of autonomous responses for comments or enquiries through selected social media channels.</p>
      </li>
      <li>
        <p>Convert into engaging property or project reviews, data-driven analytics, customised comparisons that includes community vibes, feng shui elements, surrounding activities and even market trends that correlates with the basis of demand and supply.</p>
      </li>
    </ol>
    <p>Example Scenario: You list a property and request for AI-generative, it provides automated prompts to create attention-grabbing caption, unique narrative that tells a story, and posts it to the platform’s social media channels in English or Mandarin, perhaps even with Singlish, attracting a diverse range of interests from potential buyers and co-broking agents alike with autonomous responses that shall warm-up the leads, and make the viewers in taking the initiative to contact you and beyond.</p>
    <h3 class="mt-4">11. What should I do if I need technical support?</h3>
    <p>If you encounter any issues:</p>
    <ol type="a">
      <li>
        <p>Send message to the <span class="fw-bold">Contact</span> form on our website.</p>
      </li>
      <li>
        <p>Browse the FAQs, or watch our available tutorials.</p>
      </li>
      <li>
        <p>If you need further assistance, contact our support team via EMail at support@propface.com with description or screen-shot of the error message.</p>
      </li>
    </ol>
    <h3 class="mt-4">12. How can estate agents benefit from Digital Co-Broking?</h3>
    <p>Example Scenario: You are a Singapore-based estate agent planning to expand your portfolio:</p>
    <ol type="a">
      <li>
        <p>Partner with a Malaysian agent to sell a property listed in Malaysia.</p>
      </li>
      <li>
        <p>Share commissions transparently, adhering to CEA and MIEA guidelines.</p>
      </li>
      <li>
        <p>This partnership allows you to tap into a larger market and increases your potential earnings.</p>
      </li>
    </ol>
    <h3 class="mt-4">13. Are there any disclaimers I should be aware of?</h3>
    <p>Yes, important disclaimers include:</p>
    <ol type="a">
      <li>
        <p>AI-Generative, contents or responses are automated and may not always be 100% accurate.</p>
      </li>
      <li>
        <p>Property information in our database is legally obtained from various sources but should be independently verified for accuracy.</p>
      </li>
      <li>
        <p>Our rating or scoring system is just intended as a guide for ease of references and it is neither meant to influence, nor to be relied upon for your decisions.</p>
      </li>
      <li>
        <p>The platform is not a party to the transaction, it merely facilitates the functions hence, shall not be involved or held liable in the event of disputes, and must be indemnified by the users who decide to consume any of the information or sign-up for the services.</p>
      </li>
    </ol>
    <p>Example Scenario: When using database-guided field prompt while creating your listing, always review and verify the information to ensure it is suited to represent the intended property. Similarly, verify property details independently for accuracy and completeness.</p>

    <h3 class="mt-4">14. What are the available user packages and the features that are offered?</h3>
    <p>Based on the prevailing packages and functional privileges, here are the features available:</p>
    <p class="fw-bold">For Private Users: Property Owners, Landlords, Buyers-Investors Or Tenants-Renters</p>
    <ol type="a">
      <li>
        <p><span class="fw-bold">Guest Visitor:</span> Access to view listings but limited interaction.</p>
      </li>
      <li>
        <p><span class="fw-bold">Registered User:</span> Can create and manage their own listings, receive complimentary Credits upon one-time Sign-Up, and access the bonus features.</p>
      </li>
    </ol>
    <p class="fw-bold">For Business Users: Estate Agents Or Property Managers</p>
    <ol type="a">
      <li>
        <p><span class="fw-bold">Silver (Basic):</span> Enhanced listing features, concurrent listings, feng shui mapping, inclusion of Listing Star CAP Score, and agent profile rating.</p>
      </li>
      <li>
        <p><span class="fw-bold">Gold (Advance):</span> Includes all basic features, higher concurrent listings, referral code branding, hybrid-listing assignment, project native-listings, and more Sign-Up pre-loaded Credits.</p>
      </li>
      <li>
        <p><span class="fw-bold">Platinum (Professional):</span> Covers all basic and advanced features, the most concurrent listings plus extended visibility duration, AI-generated creatives for social media interoperability, and featured agent profile with even more one-time Sign-Up free Credits.</p>
      </li>
    </ol>
    <p class="fw-bold">For Corporate Users: Estate Agencies Or Property Developers</p>
    <ol type="a">
      <li>
        <p><span class="fw-bold">Agency:</span> Unlimited concurrent listings, extended listing durations, full access to platform features including 24/7 auto-sourcing owner leads & estate agents’ onboarding, new project listings, curated properties for cross-border co-broking, listing inventory of entire sales team and AI-creative contents with autonomous predictive leads-generation responses on social media channels.</p>
      </li>
      <li>
        <p><span class="fw-bold">Developer:</span> Similar to Agency with added benefits for Developers, including project-specific listing features, Feng Shui mapping of all unit layouts for entire project, managed marketing & distribution network, post-sale revenue-generation and extended promotional tools.</p>
      </li>
    </ol>
  </div>


</template>

<script>
export default {
  name: 'FAQ',

};
</script>

<style>

</style>