<template>    
    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3 v-if="this.itemId>0"><a href="#"><i class="fa fa-angle-left"></i></a> Edit Property Listing</h3>
                    <h3 v-if="this.itemId==0"><a href="#"><i class="fa fa-angle-left"></i></a> Create Property Listing</h3>
                </div>
                <div class="progress-bar-box">
                    <div>4/5</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <!-- <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div> -->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>
                            
                            <li><router-link :to="`/post-property-listing/${itemId}`">Step 1</router-link></li>
                            <li><router-link :to="`/post-property-listing-2/${itemId}`">Step 2</router-link></li>
                            <li><router-link :to="`/post-property-listing-3/${itemId}`">Step 3</router-link></li>
                            <li><a class="active" href="javascript:;">Step 4</a></li>                            
                            <li><router-link :to="`/post-property-listing-5/${itemId}`">Step 5</router-link></li>							
                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <form @submit.prevent="submitPost">
                        <div class="property-box mb-4">							 
                            <div class="property-inner-box mb-0">
                                <div class="comman-title">
                                    <h3>Upload Files & Insert Media Links</h3>
                                </div>
                                <div class="row row-cols-1 row-cols-md-4">
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openBrowsePopup" data-id="up"  >Actual Unit Photos <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <div class="badge-list-post listofUpload_unit_photo">
                                                <span v-for="option in actual_unit_photo_list" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                    <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${option.photo_name}`" target="_blank" class="text-white"> {{option.photo_original_name}} &nbsp;</a>
                                                    <a class="fa fa-trash text-white  deleteUploadACtualUnitPhoto" data-deleteType="mb" :id="option.id"></a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <!-- <a href="#" class="comman-btn-1">Unit Layout & Site Plan <img src="frontend/images/upload-icon.svg" alt=""></a> -->
                                            <a href="javascript:;" class="comman-btn-1 openBrowsePopup"  data-id="ul"  >Unit Layout & Site Plan <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <span v-for="option in otherDocListUL" :key="option.id" :value="option.id" 
                                            :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                            >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white otherDocDelete"> {{option.photo_original_name}} &nbsp;</a>
                                                <span class="fa fa-trash text-white  deleteUploadFiles" data-deleteType="mb" :id="option.id">
                                                    <span class="visually-hidden">X</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openBrowsePopup"  data-id="mb" >Marketing Brochure
                                                <img src="frontend/images/upload-icon.svg" alt="">
                                            </a>
                                            <span
                                            v-for="option in otherDocListMB" :key="option.id" :value="option.id" 
                                            :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                            >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white otherDocDelete"> {{option.photo_original_name}} &nbsp;</a> 
                                                <span class="fa fa-trash text-white  deleteUploadFiles" data-deleteType="mb" :id="option.id">
                                                    <span class="visually-hidden">X</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openBrowsePopup"  data-id="vab" >
                                                Verify Authentic Badge
                                                <img src="frontend/images/upload-icon.svg" alt="">
                                            </a>
                                            <span v-for="option in otherDocListAVB" :key="option.id" :value="option.id" 
                                            :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                            >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white otherDocDelete"> {{option.photo_original_name}} &nbsp;</a>
                                                <span class="fa fa-trash text-white  deleteUploadFiles" data-deleteType="mb" :id="option.id">
                                                    <span class="visually-hidden">X</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <a href="javascript:;" class="comman-btn-6 openMedialinkPopup"> + Add Media Link</a>
                                        <div class="badge-list-post listofMedia_link">
                                            <span v-for="option in mediaLinksList" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`media_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}">
                                                <a :href="`${option.link_url}`" target="_blank" class="text-white"> {{option.link_name}} ( {{option.link_type}} ) &nbsp;</a>
                                                <a class="fa fa-trash text-white  deleteMediaLink" :id="option.id"></a>
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div> 
                        </div>
                        <div class="property-box">							 
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Upload Documents For Verification & Earn Verified/Trusted Badge</h3>
                                </div>
                                <div class="row row-cols-1 row-cols-md-2">
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openDocVerificationPopup" data-doc_type="Property Title & Deeds" >Property Title & Deeds <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <span v-for="option in verifyDocListPTD" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`verify_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white "> {{option.photo_original_name}} &nbsp;</a> 
                                                <a class="fa fa-trash text-white  deleteVerofyDoc" :id="option.id"></a>
                                            </span>
                                        </div>

                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openDocVerificationPopup" data-doc_type="Sale & Purchase Agreement" > Sale & Purchase Agreement
                                                    <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <span v-for="option in verifyDocListSPA" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`verify_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white "> {{option.photo_original_name}} &nbsp;</a> 
                                                <a class="fa fa-trash text-white  deleteVerofyDoc" :id="option.id"></a>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openDocVerificationPopup" data-doc_type="Loan Facility Agreement" >Loan Facility Agreement <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <span v-for="option in verifyDocListLFA" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`verify_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white "> {{option.photo_original_name}} &nbsp;</a> 
                                                <a class="fa fa-trash text-white  deleteVerofyDoc" :id="option.id"></a>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openDocVerificationPopup" data-doc_type="Utility, Tax & Assessment Bills" >Utility, Tax & Assessment Bills <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <span v-for="option in verifyDocListUTAB" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`verify_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white "> {{option.photo_original_name}} &nbsp;</a> 
                                                <a class="fa fa-trash text-white  deleteVerofyDoc" :id="option.id"></a>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openDocVerificationPopup" data-doc_type="Registered Intent To Sell (For HDB Only)" >Registered Intent To Sell (For HDB Only) <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <span v-for="option in verifyDocListRIS" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`verify_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white "> {{option.photo_original_name}} &nbsp;</a> 
                                                <a class="fa fa-trash text-white  deleteVerofyDoc" :id="option.id"></a>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <a href="javascript:;" class="comman-btn-1 openDocVerificationPopup" data-doc_type="Letter Of Appointment (Estate Agents)" >Letter Of Appointment (Estate Agents) <img src="frontend/images/upload-icon.svg" alt=""></a>
                                            <span v-for="option in verifyDocListLOA" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`verify_addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" target="_blank" class="text-white "> {{option.photo_original_name}} &nbsp;</a> 
                                                <a class="fa fa-trash text-white  deleteVerofyDoc" :id="option.id"></a>
                                            </span>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div class="d-flex justify-content-md-end">
                                <!-- <a class="btn comman-btn-6" href="/post-property-listing-3">Previous </a> -->
                                <router-link :to="`/post-property-listing-3/${itemId}`" class="btn comman-btn-6">Previous</router-link>
                                <button class="btn comman-btn-2 ms-3">Next</button>
                            </div>
                        </div>
                    </form>

                    <br>
                    <div class="d-flex">
                        <a href="#" class="comman-btn-1"  data-bs-toggle="modal" data-bs-target="#credit-top-up-modal">Credit top up</a>
                        <a href="#" class="comman-btn-1 ms-2"  data-bs-toggle="modal" data-bs-target="#otr-calculate-modal"> OTR Calculate</a>
                        <a href="#" class="comman-btn-1 ms-2"  data-bs-toggle="modal" data-bs-target="#otr-calculate-modal-result"> OTR Calculate Result </a>
                        <a href="#" class="comman-btn-1 ms-2"  data-bs-toggle="modal" data-bs-target="#otp-1"> OTP 1</a>
                        <a href="#" class="comman-btn-1 ms-2"  data-bs-toggle="modal" data-bs-target="#otp-2"> OTP 2</a>
                        <a href="#" class="comman-btn-1 ms-2"  data-bs-toggle="modal" data-bs-target="#otr-1"> OTR 1</a>
                        <a href="#" class="comman-btn-1 ms-2"  data-bs-toggle="modal" data-bs-target="#otr-2"> OTR 2</a>
                    </div>
                </div>
                
            </div>
            
        </div>

        <!-- Modal Popup -->

        <!-- upload photos modal -->
        <div class="modal fade" id="upload-photos-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">			
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                <div class="modal-body text-center">
                    <form id="file-upload-form" class="uploader">
                        <input id="file-upload" type="file" @change="onFileChange" name="fileUploadImg" multiple accept="image/*" />
                            <input type="hidden" name="type_photos"  id="type_photos" value="1">
                            <label for="file-upload" id="file-drag">
                                <img id="file-image" src="#" alt="Preview" class="hidden">
                                <div id="start">
                                    <img src="frontend/images/big-upload-icom.svg" alt="">
                                    <div>
                                        <h5 class="title_photos_upload">Please Upload Your Site Plan</h5>
                                        <p class="mb-0">&lt;Types Of File Formats&gt; JPG, PDF, SVG <br>
                                        &lt;Max Size Per File&gt; 05 - 10 PX <br>
                                        &lt;Max No. Of Files&gt; 4 </p>
                                    </div>	
                                    <div id="notimage" class="hidden">Please select an image</div>
                                    <!-- <span id="file-upload-btn" class="btn btn-primary">Select a file</span> -->
                                </div>
                                <div id="response" class="hidden">
                                    <div id="messages"></div>
                                    <progress class="progress" id="file-progress" value="0">
                                        <span>0</span>%
                                    </progress>
                                </div>
                            </label>
                        </form>
                    </div>
                </div>
            </div>
        </div>  
        
        
        <!-- upload Verify Doc modal -->
        <div class="modal fade" id="upload-verifyDoc-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">			
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                <div class="modal-body text-center">
                    <form id="verify_doc-upload-form" class="uploader">
                        <input id="file-upload-verify" type="file" @change="onFileVerifyDocChange" name="fileUpload" multiple accept="image/*,application/pdf" />
                            <input type="hidden" name="verify_doc_type"  id="verify_doc_type" value="">
                            <label for="file-upload-verify" id="file-drag">
                                <img id="file-image" src="#" alt="Preview" class="hidden">
                                <div id="start">
                                    <img src="frontend/images/big-upload-icom.svg" alt="">
                                    <div>
                                        <h5 class="title_verify_doc_type">Please Upload Your Site Plan</h5>
                                        <p class="mb-0">&lt;Types Of File Formats&gt; JPG, PDF, SVG <br>
                                        &lt;Max Size Per File&gt; 05 - 10 PX <br>
                                        &lt;Max No. Of Files&gt; 4 </p>
                                    </div>	
                                    <div id="notimage" class="hidden">Please select an image</div>
                                    <!-- <span id="file-upload-btn" class="btn btn-primary">Select a file</span> -->
                                </div>
                                <div id="response" class="hidden">
                                    <div id="messages"></div>
                                    <progress class="progress" id="file-progress" value="0">
                                        <span>0</span>%
                                    </progress>
                                </div>
                            </label>
                        </form>
                    </div>
                </div>
            </div>
        </div>  

        <!--  Add Media linkst -->
        <div class="modal fade" id="add-media-link-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                    <div class="modal-body">                           
                       
                        <div class="comman-title">
                            <h3>Add Media Link</h3>
                        </div>
                        <form @submit.prevent="submitAddMediaLink">
                            <div class="row row-cols-md-1">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Link type</label>
                                        <select class="form-control" v-model="form.link_type">
                                            <option value="" disabled>Link type</option>
                                            <option>OutsideLink</option>
                                            <option>Embed</option>
                                        </select>
                                        <span  class="error" v-if="errors.link_type">{{ errors.link_type }}</span>
                                    </div>
                                </div>                                   
                                <div class="col">
                                    <div class="form-group">
                                        <label>Link name</label>
                                        <input id="link_name" class="form-control" type="text" v-model="form.link_name" placeholder="Link name">
                                        <span  class="error" v-if="errors.link_name">{{ errors.link_name }}</span>
                                    </div>
                                </div>                                   
                                <div class="col">
                                    <div class="form-group">
                                        <label>Link URL</label>
                                        <input id="link_url" class="form-control" type="text" v-model="form.link_url" placeholder="Link URL">
                                        <span  class="error" v-if="errors.link_url">{{ errors.link_url }}</span>
                                    </div>
                                </div>                                   
                            </div> 
                            <button class="btn comman-btn-2" type="submit">Save</button>   
                        </form>    
                    </div>
                </div>
            </div>
        </div>

        <!-- credit top up -->
        <div class="modal fade" id="credit-top-up-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                    <div class="modal-body text-center">
                        <img src="frontend/images/credit-top-up.png" width="200px" class="mb-4" alt="">
                        <h4>Please top up! </h4>
                        <h5>You don't have sufficient credit to use this feature. </h5>
                        <p>The AI-generated content will require a minimum of 10 credits if the listing is approved with a score above 80%.</p>
                        <div class="">
                            <a href="#" class="comman-btn-2 ms-auto me-auto mb-3 w-50">Top-Up</a>
                            <a href="#" class="comman-btn-6 w-50 ms-auto me-auto">Go Back</a>
                        </div>				
                    </div>
                </div>
            </div>
        </div>
        
        <!-- OTR Calculate -->
        <div class="modal fade" id="otr-calculate-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                    <div class="modal-body">
                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-For-Rent-tab" data-bs-toggle="pill" data-bs-target="#pills-For-Rent" type="button" role="tab" aria-controls="pills-For-Rent" aria-selected="true">For-Rent</button>
                            </li>
                            <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pills-Calculate-tab" data-bs-toggle="pill" data-bs-target="#pills-Calculate" type="button" role="tab" aria-controls="pills-Calculate" aria-selected="false">Calculate</button>
                            </li>						
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade" id="pills-For-Rent" role="tabpanel" aria-labelledby="pills-For-Rent-tab">...</div>
                            <div class="tab-pane fade show active" id="pills-Calculate" role="tabpanel" aria-labelledby="pills-Calculate-tab">
                                <div class="otr-calculate-title">
                                    <h2>Rental Term Sheet ( Proposal )</h2>
                                    <p>*Subject To Agreement & Payment Consideration</p>
                                </div>
                                <div class="comman-title">
                                    <h3>Offer To Rent</h3>
                                </div>
                                <div class="row row-cols-md-2">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Asking Monthly Rent ( S$ )</label>
                                            <input class="form-control" type="text" placeholder="Asking Monthly Rent ( S$ )">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Preferred Term (Mths)</label>
                                            <select class="form-control">
                                                <option>12</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Security Deposit ( Mths )</label>
                                            <select class="form-control">
                                                <option>1</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Utility Deposit ( Mths )</label>
                                            <select class="form-control">
                                                <option>0.5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Lump-Sum Pre-Pay Rent (Mths) <i class="fa fa-question-circle"></i> </label>
                                            <select class="form-control">
                                                <option>3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Proposed Pre-Pay Rebate ( % ) <i class="fa fa-question-circle"></i> </label>
                                            <select class="form-control">
                                                <option>2.5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">									
                                        <div class="form-group">										
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="Diversity-Friendly">
                                                <label class="form-check-label w-100" for="Diversity-Friendly">
                                                    Diversity Friendly <i class="fa fa-question-circle"></i>
                                                </label>
                                            </div>	
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="Pets-Allowed">
                                                <label class="form-check-label w-100" for="Pets-Allowed">
                                                    Pets Allowed <i class="fa fa-question-circle"></i>
                                                </label>
                                            </div>	
                                        </div>
                                    </div>
                                    <div class="col">									
                                        <div class="form-group">										
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="Enable-Rent-Financing">
                                                <label class="form-check-label w-100" for="Enable-Rent-Financing">
                                                    Enable Rent Financing <i class="fa fa-question-circle"></i>
                                                </label>
                                            </div>	
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="I-Agree-To">
                                                <label class="form-check-label w-100" for="I-Agree-To">
                                                    I Agree To The <a href="#"> Terms Of Use </a>
                                                </label>
                                            </div>	
                                        </div>
                                    </div>
                                </div>
                            </div>						
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- OTR Calculate -->
        <div class="modal fade" id="otr-calculate-modal-result" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                    <div class="modal-body">
                        <div class="otp-calculate-title">
                            <h2>Estimated Rent Cashflow</h2>
                            <p>*Subject To Agreement & Payment Consideration</p>
                        </div>
                        <div class="table-responsive custom-table-design">
                            <table class="table-borderless">
                                <thead class="bg-light">
                                    <tr>
                                        <th>Total Payable By Renter/Tenant</th>
                                        <th>8,100.00</th>
                                        <th>8,300.00</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Security Deposit</td>
                                        <td>2 Mths</td>
                                        <td>2,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>Utility Deposit</td>
                                        <td>0.5 Mths</td>
                                        <td>500.00</td>
                                    </tr>
                                    <tr>
                                        <td>Lump-Sum pre-pay rent</td>
                                        <td>6 Mths</td>
                                        <td>6,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>proposed pre-pay rebate</td>
                                        <td class="text-danger">(5.00%)</td>
                                        <td class="text-danger">(300.00)</td>
                                    </tr>
                                    <tr>
                                        <td>Provisional platform free on payment</td>
                                        <td>1.50%</td>
                                        <td>123.00</td>
                                    </tr>
                                </tbody>
                                <thead class="bg-light">
                                    <tr>
                                        <th>Total Receivable by landlord</th>
                                        <th>7,200.00</th>
                                        <th>7,200.00</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Security Deposit</td>
                                        <td>2 Mths</td>
                                        <td>2,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>Utility Deposit</td>
                                        <td>0.5 Mths</td>
                                        <td>500.00</td>
                                    </tr>
                                    <tr>
                                        <td>Lump-Sum pre-pay rent</td>
                                        <td>6 Mths</td>
                                        <td>6,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>proposed pre-pay rebate</td>
                                        <td class="text-danger">(5.00%)</td>
                                        <td class="text-danger">(300.00)</td>
                                    </tr>
                                    <tr>
                                        <td>Provisional platform free on payment</td>
                                        <td class="text-danger">1.50%</td>
                                        <td class="text-danger">123.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- OTP 1 -->
        <div class="modal fade otp" id="otp-1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                    <div class="modal-body">
                        <div class="otp-calculate-title">
                            <h2>Generate Sale Proposal</h2>
                            <p>*Subject To Agreement & Payment Consideration</p>
                        </div>
                        <div class="comman-title">
                            <h3>Offer To Purchase (OTP)</h3>
                        </div>
                        <form>
                            <div class="row row-cols-sm-2">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Offer Price ($)</label>
                                        <input type="text" class="form-control" placeholder="Offer Price ($)">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Loan Margin (%)</label>
                                        <select class="form-control">
                                            <option>Loan Margin</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Discount (%)</label>
                                        <select class="form-control">
                                            <option>Discount</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Additional Rebate (%)</label>
                                        <select class="form-control">
                                            <option>Additional Rebate (%)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Earnest Deposit (%)</label>
                                        <select class="form-control">
                                            <option>Earnest Deposit (%)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Estate Agent Fee (%) By Purchaser</label>
                                        <select class="form-control">
                                            <option>Estate Agent Fee (%) By Purchaser</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="text-end">
                                <button class="comman-btn-2 d-inline-block text-end">Next</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- OTP 2 -->
        <div class="modal fade otp" id="otp-2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                    <div class="modal-body">
                        <div class="otp-calculate-title">
                            <h2>Generate Offer-To-Purchase (OTP) Proposal</h2>
                            <p>*Subject To Agreement & Payment Consideration</p>
                        </div>

                        <div class="table-responsive">
                            <table class="table-borderless">							
                                <tbody>
                                    <tr>
                                        <td>S&PA Price Before Price Discount</td>
                                        <td>100.00%</td>
                                        <td>200,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>S&PA Price After Price Discount</td>
                                        <td>95.00%</td>
                                        <td>190,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>Additional Rebate</td>
                                        <td>1.00%</td>
                                        <td>1,900.00</td>
                                    </tr>
                                    <tr>
                                        <td>Loan Amount</td>
                                        <td>80.00%</td>
                                        <td>152,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>Earnest Deposit  On S&PA Price</td>
                                        <td>2.00%</td>
                                        <td>3,800.00</td>
                                    </tr>
                                    <tr>
                                        <td>Provisional Platform Free On Payment</td>
                                        <td>1.50%</td>
                                        <td>5.70</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div class="gray-box">
                            <div>Total Payable By Purchaser (Initial)</div>
                            <div>3,805.70</div>
                        </div>
                        <div class="gray-box">
                            <div>Total Receivable by seller/vendor (initial)</div>
                            <div>3,800.00</div>
                        </div>

                        <form>
                            <div class="comman-title mt-5">
                                <h3>Property Details</h3>
                            </div>
                            <div class="row row-cols-sm-2">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Property Name</label>
                                        <input type="text" class="form-control" placeholder="Property Name">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Property Address</label>
                                        <input type="text" class="form-control" placeholder="Property Address">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Property Type</label>
                                        <select class="form-control">
                                            <option>Property Type</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Floor Area/Built-Up (SQF)</label>
                                        <select class="form-control">
                                            <option>Floor Area/Built-Up (SQF)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Furnishing Level</label>
                                        <select class="form-control">
                                            <option>Furnishing Level</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>S&PA Date</label>
                                        <select class="form-control">
                                            <option>S&PA Date</option>
                                        </select>
                                    </div>
                                </div> 
                            </div>

                            <div class="comman-title mt-3">
                                <h3>Proposer Information (Buyer/Estate Agent)</h3>
                            </div>
                            <div class="row row-cols-sm-2">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input type="text" class="form-control" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>EMail Address</label>
                                        <input type="text" class="form-control" placeholder="EMail Address">
                                    </div>
                                </div>							
                                <div class="col">
                                    <div class="form-group">
                                        <label>Phone Number</label>
                                        <input type="text" class="form-control" placeholder="Phone Number">
                                    </div>
                                </div>
                                <div class="col">
                                    <a href="#"><img src="frontend/images/orange-file.svg" alt=""> OTP Form / S&PA</a> <i class="fa fa-question-circle"></i>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="terms">
                                        <label class="form-check-label w-100" for="terms">
                                            <b>I Agree To The <a href="#"> Terms Of Use</a> </b>
                                        </label>
                                    </div>	
                                </div>
                                <div class="col">
                                    <div class="text-end">
                                        <button class="comman-btn-2 d-inline-block text-end">Generate Proposal</button>
                                    </div>
                                </div>	
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- OTR 1 -->
        <div class="modal fade otp" id="otr-1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                    <div class="modal-body">
                        <div class="otp-calculate-title">
                            <h2>Rental Term Sheet (Proposal)</h2>
                            <p>*Subject To Agreement & Payment Consideration</p>
                        </div>
    

                        <form>
                            <div class="comman-title">
                                <h3>Offer To Rent (OTR)</h3>
                            </div>
                            <div class="row row-cols-sm-2">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Monthly Rent Offer (s)</label>
                                        <input type="text" class="form-control" placeholder="Monthly Rent Offer (s)">
                                    </div>
                                </div> 
                                <div class="col">
                                    <div class="form-group">
                                        <label>Preferred Term (Mths)</label>
                                        <select class="form-control">
                                            <option>12</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Security Deposit (Mths)</label>
                                        <select class="form-control">
                                            <option>1</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Utility Deposit (Mths)</label>
                                        <select class="form-control">
                                            <option>0.5</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Lump-Sum Pre-Pay Rent (Mths) <i class="fa fa-question-circle"></i></label>
                                        <select class="form-control">
                                            <option>3</option>
                                        </select>
                                    </div>
                                </div> 
                                <div class="col">
                                    <div class="form-group">
                                        <label>Proposed Pre-Pay Rebate (%) <i class="fa fa-question-circle"></i></label>
                                        <select class="form-control">
                                            <option>2.5</option>
                                        </select>
                                    </div>
                                </div>  
                                <div class="col">								
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="Rent-Financing-Option">
                                        <label class="form-check-label w-100" for="Rent-Financing-Option">
                                            <b>Rent Financing Option <i class="fa fa-question-circle"></i></b>
                                        </label>
                                    </div>	
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="terms-1">
                                        <label class="form-check-label w-100" for="terms-1">
                                            <b>I Agree To The <a href="#"> Terms Of Use</a> <i class="fa fa-question-circle"></i></b>
                                        </label>
                                    </div>	
                                </div>
                                <div class="col">
                                    <div class="text-end">
                                        <button class="comman-btn-2 d-inline-block text-end">Next</button>
                                    </div>
                                </div>	
                            </div>
    
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- OTR 2-->
        <div class="modal fade otp" id="otr-2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                    <div class="modal-body">
                        <div class="otp-calculate-title">
                            <h2>Generate Offer-To-Rent (OTR) Proposal</h2>
                            <p>*Subject To Agreement & Payment Consideration</p>
                        </div>
                        
                        <div class="table-responsive custom-table-design">
                            <table class="table-borderless">
                                <thead class="bg-light">
                                    <tr>
                                        <th>Total Payable By Renter/Tenant</th>
                                        <th>8,200.00</th>
                                        <th>8,323.00</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Security Deposit</td>
                                        <td>2 Mths</td>
                                        <td>2,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>Utility Deposit</td>
                                        <td>0.5 Mths</td>
                                        <td>500.00</td>
                                    </tr>
                                    <tr>
                                        <td>Lump-Sum pre-pay rent</td>
                                        <td>6 Mths</td>
                                        <td>6,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>proposed pre-pay rebate</td>
                                        <td class="text-danger">(5.00%)</td>
                                        <td class="text-danger">(300.00)</td>
                                    </tr>
                                    <tr>
                                        <td>Provisional platform free on payment</td>
                                        <td>1.50%</td>
                                        <td>123.00</td>
                                    </tr>
                                </tbody>
                                <thead class="bg-light">
                                    <tr>
                                        <th>TOTAL Receivable By Landlord</th>
                                        <th>7,200.00</th>
                                        <th>7,200.00</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Security Deposit</td>
                                        <td>2 Mths</td>
                                        <td>2,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>Utility Deposit</td>
                                        <td>0.5 Mths</td>
                                        <td>500.00</td>
                                    </tr>
                                    <tr>
                                        <td>Lump-Sum pre-pay rent</td>
                                        <td>6 Mths</td>
                                        <td>6,000.00</td>
                                    </tr>
                                    <tr>
                                        <td>proposed pre-pay rebate</td>
                                        <td class="text-danger">(5.00%)</td>
                                        <td class="text-danger">(300.00)</td>
                                    </tr>
                                    <tr>
                                        <td>Provisional platform free on payment</td>
                                        <td>1.50%</td>
                                        <td>123.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <form>
                            <div class="comman-title">
                                <h3>Property Details</h3>
                            </div>
                            <div class="row row-cols-sm-2">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Property Name</label>
                                        <input type="text" class="form-control" placeholder="Property Name">
                                    </div>
                                </div> 
                                <div class="col">
                                    <div class="form-group">
                                        <label>Property Address</label>
                                        <input type="text" class="form-control" placeholder="Property Address">
                                    </div>
                                </div> 
                                <div class="col">
                                    <div class="form-group">
                                        <label>Property Type</label>
                                        <select class="form-control">
                                            <option>Property Type</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Floor Area/Built-Up (SQF)</label>
                                        <select class="form-control">
                                            <option>Floor Area/Built-Up (SQF)</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Furnishing Level</label>
                                        <select class="form-control">
                                            <option>Furnishing Level</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>Rent Free Fr-To</label>
                                        <select class="form-control">
                                            <option>Rent Free Fr-To</option>
                                        </select>
                                    </div>
                                </div> 
                            </div>
                            
                            <div class="comman-title">
                                <h3>Proposer Information (Renter-Tenant/Estate Agent)</h3>
                            </div>
                            <div class="row row-cols-sm-2">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input type="text" class="form-control" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label>EMail Address</label>
                                        <input type="text" class="form-control" placeholder="EMail Address">
                                    </div>
                                </div>							
                                <div class="col">
                                    <div class="form-group">
                                        <label>Phone Number</label>
                                        <input type="text" class="form-control" placeholder="Phone Number">
                                    </div>
                                </div>
                                <div class="col">								
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="otr-foen-ta-1">
                                        <label class="form-check-label w-100" for="otr-foen-ta-1">
                                            <!-- <b>OTP Form / TA <i class="fa fa-question-circle"></i></b> -->
                                            <a href="#"><img src="frontend/images/orange-file.svg" alt=""> OTP Form / TA</a> <i class="fa fa-question-circle"></i>
                                        </label>
                                    </div>	
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="terms-2">
                                        <label class="form-check-label w-100" for="terms-2">
                                            <b>I Agree To The <a href="#"> Terms Of Use</a> <i class="fa fa-question-circle"></i></b>
                                        </label>
                                    </div>	
                                </div>
                                <div class="col">
                                    <div class="text-end">
                                        <button class="comman-btn-2 d-inline-block text-end">Generate Proposal</button>
                                    </div>
                                </div>	
                            </div>
    
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </section>

</template>
  
  <script>
//   import  Modal  from 'bootstrap';
  import { CONSTANT } from '@/constants/constants';
  import { OPTIONS_GROUP_1 } from '@/constants/constantsProperty';
  import { mapState } from 'vuex';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';

//   import $ from 'jquery';
//   import 'frontend/js/bootstrap.bundle.min.js';
// import e from 'express';
  export default {
    name: 'PostPropertyListingStep4', 
    data() {
        return {
            CONSTANT,
            otherDocList: [],
            otherDocListMB: [],
            otherDocListUL: [],
            otherDocListAVB: [],
            mediaLinksList: [],
            actual_unit_photo_list: [],

            verifyDocListPTD: [],
            verifyDocListSPA: [],
            verifyDocListLFA: [],
            verifyDocListUTAB: [],
            verifyDocListRIS: [],
            verifyDocListLOA: [],

            selectedGroup1: '',
            optionsGroup1: OPTIONS_GROUP_1,
            unitiPhotos: [],
            form: {
                link_type:'',
                link_name:''
            },
            errors: {}

        }
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
        itemId() {
            return this.$route.params.id;
        }
    },
    created() {
        this.fetchOptions();
    },
    
    methods: {
        validateAddMediaLink() {            
            this.errors = {};
            if (!this.form.link_type) {
                this.errors.link_type = 'Link type  is required.';
            }
            if (!this.form.link_name) {
                this.errors.link_name = 'Link name  is required.';
            }
            if (!this.form.link_url) {
                this.errors.link_url = 'Link URL  is required.';
            }
            return Object.keys(this.errors).length === 0;
        },
        async submitPost() {            
            this.$router.push('/post-property-listing-5/'+this.itemId);  
        },  
        async submitAddMediaLink() {
            
            if (this.validateAddMediaLink()) {
                try {
                    const token = localStorage.getItem('userToken');
                    const response = await this.$axios.post('api/add-property-listing-media-link', {
                                user_id:this.user.id,
                                api_token:token,                    
                                property_listing_id: this.itemId,
                                link_type: this.form.link_type,
                                link_name: this.form.link_name,
                                link_url: this.form.link_url
                    });
                    var res_data = response.data.output;
                    toastr.success('Media Link added', 'Success', {progressBar: true, closeButton: true});
                    setTimeout(function(){
                        window.$(".btn-close").trigger('click');
                        var html = '<span  class="badge bg-secondary rounded-pill media_addi_'+res_data.id+' position-relative featurelist-badge" value="27">\
                                        <a  href="'+res_data.link_url+'" target="_blank" class="text-white">\
                                                '+res_data.link_name+' ('+res_data.link_type+') &nbsp;</a>\
                                        <a  class="fa fa-trash text-white deleteMediaLink" id="'+res_data.id+'"></a>\
                                    </span>';
                        window.$(".listofMedia_link").append(html);
                    },500);
                } catch (error) {
                            
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        async onFileChange(event) {
            var type_photos = window.$("#type_photos").val();

            if(type_photos == 'up'){

                const files = event.target.files;
                this.selectedFiles = Array.from(files);
                if (this.selectedFiles) {
                    
                    const token = localStorage.getItem('userToken');
                    const formData = new FormData();

                    // Append files to FormData
                    this.selectedFiles
                    .forEach(file => {
                        formData.append('actual_unit_photos[]', file);
                    });
                    formData.append('dataJson', JSON.stringify({
                    user_id: this.user.id,
                    api_token: token,
                    property_listing_id: this.itemId,
                    }));


                    try {
                        const response = await this.$axios.post('api/add-actual-unit-photos', formData, {
                            headers: {
                            'Content-Type': 'multipart/form-data',
                            },
                        });
                        console.log(response.data);
                        if(response.data.status == 1){
                            var res_data = response.data.output;
                            this.actual_unit_photo_list = res_data;
                            toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                            setTimeout(function(){
                                window.$(".btn-close").trigger('click');
                                // var html = '<span  class="badge bg-secondary rounded-pill addi_'+res_data.id+' position-relative featurelist-badge" value="'+res_data.id+'">\
                                //                 <a  href="'+CONSTANT.IMAGE_URL+'imagesPropertyActualUnit/'+res_data.photo_name+'" target="_blank" class="text-white">\
                                //                         '+res_data.photo_original_name+' &nbsp;</a>\
                                //                 <span  class="fa fa-trash text-white deleteUploadFiles" data-deletetype="mb" id="'+res_data.id+'"></span>\
                                //             </span>';
                                // window.$(".listofUpload_unit_photo").append(html);

                            },500);
                        }else{
                            toastr.error(response.data.message, 'Error11', { timeOut: 3000 });
                        }
                    } catch (error) {                
                        console.log(error);
                        if (error.response && error.response.data) {
                            toastr.error(error.response.data.errors, 'Error', { timeOut: 3000 });
                            this.errors = this.extractErrors(error.response.data.errors || {});
                        } else {
                            console.error('An unexpected error occurred:', error);
                        }
                    } finally {
                    this.loading = false;
                    }
                }

            }else{
                var doc_type_val = '';
                if(type_photos == 'ul'){
                    doc_type_val = 'Unit Layout & Site Plan';
                }
                if(type_photos == 'mb'){
                    doc_type_val = 'Marketing Brochure';
                }
                if(type_photos == 'vab'){
                    doc_type_val = 'Verify Authentic Badge';
                }
                const files = event.target.files;
                this.selectedFiles = Array.from(files);
                if (this.selectedFiles) {
                    
                    const token = localStorage.getItem('userToken');
                    const formData = new FormData();

                    // Append files to FormData
                    this.selectedFiles
                    .forEach(file => {
                        formData.append('doc_other_photo[]', file);
                    });
                    formData.append('dataJson', JSON.stringify({
                    user_id: this.user.id,
                    api_token: token,
                    property_listing_id: this.itemId,
                    doc_type: doc_type_val,
                    }));


                    try {
                        const response = await this.$axios.post('api/add-documents-other-photos', formData, {
                            headers: {
                            'Content-Type': 'multipart/form-data',
                            },
                        });

                        if(response.data.status == 1){
                            res_data = response.data.output;
                            this.otherDocListMB = res_data.filter(doc => doc.doc_type === 'Marketing Brochure');
                            this.otherDocListAVB =  res_data.filter(doc => doc.doc_type === 'Verify Authentic Badge');
                            this.otherDocListUL =  res_data.filter(doc => doc.doc_type === 'Unit Layout & Site Plan'); 
                            toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                            setTimeout(function(){
                                window.$(".btn-close").trigger('click');
                            },500);
                        }else{
                            toastr.error(response.data.message, 'Error11', { timeOut: 3000 });
                        }
                    } catch (error) {                
                        if (error.response && error.response.data) {
                            this.errors = this.extractErrors(error.response.data.errors || {});
                        } else {
                            console.error('An unexpected error occurred:', error);
                        }
                    } finally {
                    this.loading = false;
                    }
                }
            }

        },
        async onFileVerifyDocChange(event) {
            
            var doc_type = window.$("#verify_doc_type").val();
            const files = event.target.files;
            this.selectedFiles = Array.from(files);
            if (this.selectedFiles) {
                
                const token = localStorage.getItem('userToken');
                const formData = new FormData();

                // Append files to FormData
                this.selectedFiles
                .forEach(file => {
                    formData.append('verification_doc[]', file);
                });
                formData.append('dataJson', JSON.stringify({
                user_id: this.user.id,
                api_token: token,
                property_listing_id: this.itemId,
                doc_type: doc_type,
                }));


                try {
                    const response = await this.$axios.post('api/add-verification-documents', formData, {
                        headers: {
                        'Content-Type': 'multipart/form-data',
                        },
                    });
                    if(response.data.status == 1){
                        var res_data = response.data.output;
                        this.verifyDocListPTD =  res_data.filter(doc => doc.doc_type === 'Property Title & Deeds');
                        this.verifyDocListSPA =  res_data.filter(doc => doc.doc_type === 'Sale & Purchase Agreement');
                        this.verifyDocListLFA =  res_data.filter(doc => doc.doc_type === 'Loan Facility Agreement');                    
                        this.verifyDocListUTAB = res_data.filter(doc => doc.doc_type === 'Utility, Tax & Assessment Bills');                    
                        this.verifyDocListRIS =  res_data.filter(doc => doc.doc_type === 'Registered Intent To Sell (For HDB Only)');                    
                        this.verifyDocListLOA =  res_data.filter(doc => doc.doc_type === 'Letter Of Appointment (Estate Agents)'); 
                        toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                        setTimeout(function(){
                            window.$(".btn-close").trigger('click');
                        },500);
                    }else{
                        toastr.error(response.data.message, 'Error11', { timeOut: 3000 });
                    }
                } catch (error) {                
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                this.loading = false;
                }
            }
        },
        async onFileChangeSingle(event) {
            
            this.selectedFile = event.target.files[0];
            console.log("File selected:", this.selectedFile);
            if (this.selectedFile) {
                // Generate a preview URL
                this.imageUrl = URL.createObjectURL(this.selectedFile);
                this.computedImageSrc = URL.createObjectURL(this.selectedFile);
                
                const token = localStorage.getItem('userToken');
                // Create a FormData object
                const formData = new FormData();
                formData.append('actual_unit_photos[]', this.selectedFile);
                formData.append('dataJson', JSON.stringify({
                user_id: this.user.id,
                api_token: token,
                property_listing_id: this.itemId,
                }));


                try {
                const response = await this.$axios.post('api/add-actual-unit-photos', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                });
                toastr.success(response.data.message, 'Success', { timeOut: 3000 });


                    const response_profile = await this.$axios.post('/api/profile', {
                    api_token: token
                    }); 

                    console.log('API Response:', response_profile.data); // Check API response
                    await this.$store.dispatch('profileUpdate', response_profile.data);
                    
                //await this.$store.dispatch('profileUpdate', response.data);
                } catch (error) {                
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                this.loading = false;
                }
            }
        },
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },

        async fetchOptions() {

            const token = localStorage.getItem('userToken');
            if(this.itemId > 0){                
                try {
                    const response = await this.$axios.post('api/property-detail',
                    {                    
                        user_id :this.user.id,
                        api_token: token,
                        property_listing_id:this.itemId,                    
                    });
                    this.editData = response.data.output[0];
                    this.actual_unit_photo_list =  this.editData.actual_unit_photo;

                    this.otherDocList = this.editData.doc_other_photos;
                    this.otherDocListMB =  this.otherDocList.filter(doc => doc.doc_type === 'Marketing Brochure');
                    this.otherDocListAVB =  this.otherDocList.filter(doc => doc.doc_type === 'Verify Authentic Badge');
                    this.otherDocListUL =  this.otherDocList.filter(doc => doc.doc_type === 'Unit Layout & Site Plan');                    
                    this.mediaLinksList = this.editData.media_links;

                    this.verifyDocList = this.editData.verification_document;
                    this.verifyDocListPTD =  this.verifyDocList.filter(doc => doc.doc_type === 'Property Title & Deeds');
                    this.verifyDocListSPA =  this.verifyDocList.filter(doc => doc.doc_type === 'Sale & Purchase Agreement');
                    this.verifyDocListLFA =  this.verifyDocList.filter(doc => doc.doc_type === 'Loan Facility Agreement');                    
                    this.verifyDocListUTAB =  this.verifyDocList.filter(doc => doc.doc_type === 'Utility, Tax & Assessment Bills');                    
                    this.verifyDocListRIS =  this.verifyDocList.filter(doc => doc.doc_type === 'Registered Intent To Sell (For HDB Only)');                    
                    this.verifyDocListLOA =  this.verifyDocList.filter(doc => doc.doc_type === 'Letter Of Appointment (Estate Agents)');                    



                    // this.otherDocList = this.editData.doc_other_photos.map(item => ({
                    //     id: item.id,
                    //     doc_type: item.doc_type,
                    //     photo_name: item.photo_name,
                    //     photo_original_name: item.photo_original_name
                    // }));

                } catch (error) {
                    console.error('Error fetching options:', error);
                }
            }
        },
        async deleteDocUnitPhoto(id) {
            const token = localStorage.getItem('userToken');
            try {
                await this.$axios.post('api/delete-actual-unit-photo',
                {                    
                    api_token: token,
                    user_id:this.user.id,
                    photo_id:id,


                });
                window.$(".addi_"+id).remove();
                
            } catch (error) {
                toastr.error('Error fetching options:')
            }
        },
        
        async deleteDocFiles(id) {            
            const token = localStorage.getItem('userToken');
            try {
                await this.$axios.post('api/delete-documents-other-photo',
                {                    
                    api_token: token,
                    user_id:this.user.id,
                    doc_photo_id:id,
                });
                window.$(".addi_"+id).remove();
                
            } catch (error) {
                toastr.error('Error fetching options:')
            }
        },
        
        async deleteMediaLink(id) {            
            const token = localStorage.getItem('userToken');
            try {
                await this.$axios.post('api/delete-property-listing-media-link',
                {                    
                    api_token: token,
                    user_id:this.user.id,
                    medialink_id:id,
                });
                window.$(".media_addi_"+id).remove();
                
            } catch (error) {
                toastr.error('Error fetching options:')
            }
        },
        
        async deleteVerofyDoc(id) {            
            const token = localStorage.getItem('userToken');
            try {
                await this.$axios.post('api/delete-verification-document',
                {                    
                    api_token: token,
                    user_id:this.user.id,
                    doc_id:id,
                });
                window.$(".verify_addi_"+id).remove();
                
            } catch (error) {
                toastr.error('Error fetching options:')
            }
        },
        setupEventListeners() {
            const self = this; // Save reference to `this`
            
            window.$(document).on('click', '.deleteUploadACtualUnitPhoto', function() {        
                const id = window.$(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteDocUnitPhoto(id);
                }
                });
            });
            
            window.$(document).on('click', '.deleteUploadFiles', function() {        
                const id = window.$(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteDocFiles(id);
                }
                });
            });
            
            window.$(document).on('click', '.deleteMediaLink', function() {        
                const id = window.$(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteMediaLink(id);
                }
                });
            });
            
            window.$(document).on('click', '.deleteVerofyDoc', function() {        
                const id = window.$(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteVerofyDoc(id);
                }
                });
            });
        }
    },
    mounted() {
        this.setupEventListeners();
    } 
  }

  window.$(document).on("click",".openMedialinkPopup",function(){        
    window.$('#add-media-link-modal').modal('show');
    window.$('#link_name').val('');
    window.$('#link_url').val('');
  });

  window.$(document).on("click",".openBrowsePopup",function(){    
    var typeOfPhotos = window.window.$(this).data("id");
    window.$("#type_photos").val(typeOfPhotos);
    
    window.$("input[name='fileUploadImg']").attr('accept',"image/*,application/pdf");
    if(typeOfPhotos == 'up'){
        window.$("input[name='fileUploadImg']").attr('accept',"image/*");
        window.$(".title_photos_upload").html("Please Upload Unit Photos");
    }else if(typeOfPhotos == 'ul'){
        window.$(".title_photos_upload").html("Please Upload  Unit Layout & Site Plan");
    }else if(typeOfPhotos == 'mb'){
        window.$(".title_photos_upload").html("Please Upload  Marketing Brochure ");
    }else{
        window.$(".title_photos_upload").html("Please Upload  Verify Authentic Badge ");
    }
    window.$('#upload-photos-modal').modal('show');
    // const myModal = new Modal(document.getElementById('upload-photos-modal'));
    // myModal.show();

  })

  window.$(document).on("click",".openDocVerificationPopup",function(){    
    var typeOfPhotos = window.window.$(this).data("doc_type");
    window.$("#verify_doc_type").val(typeOfPhotos);
    window.$('#upload-verifyDoc-modal').modal('show');
    window.$(".title_verify_doc_type").html(typeOfPhotos);    

  })
  </script>
  
  <style scoped>
  .deleteUploadFiles{
    cursor: pointer;
  }
  .badge{
    margin-right: 1px;
  }
  /* Add styles specific to the About Us page here */
  </style>
  