<template>
  <section class="payment-se">
    <div class="container">
      <div class="comman-title">
        <h3>Payment Success</h3>
      </div>
      <div class="payment-box">
        <div class="form-check mb-2">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault9" checked disabled>
          <label class="form-check-label w-100" for="flexCheckDefault9">
            <b>Payment success</b> <span class="pull-right"><img src="frontend/images/visa.jpg" alt=""> <img src="frontend/images/master-card.png" alt=""></span>
          </label>
        </div>
      </div>
      <div class="d-md-flex protect-payment">
        <div class="d-flex align-items-center">
          <img src="frontend/images/lock.svg" class="me-3">
          <p class="mb-0">We protect your payment information using<br> encryption to provide bank-level security.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['isAuthenticated', 'user']),
  },
  async created() {

  },
  methods: {
  }
};
</script>
