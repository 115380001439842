<template>    
    <section class="create-property-listing-page">
			<div class="container">
				<div class="text-center">
					<div class="comman-title">
						<h2>Create your property listing in minutes!</h2>
						<p>Add quick details and sign up with your mobile number to Add your property.</p>
					</div>
					<div class="d-flex justify-content-center">
						<router-link :to="`/widget-property-post-1/${id}/${idType}`"><a href="javascript:;" class="comman-btn-2">Get Started</a></router-link>
					</div>
				</div>
			</div>
		</section> 
</template>
  
<script> 
import { mapState } from 'vuex';
  export default {
    name: 'Widget0Page', 
    
	computed: {
        ...mapState(['isAuthenticated', 'user']),
        id() {
			return this.$route.params.id;
		},
		idType() {
			return this.$route.params.id_type;
		},   
    },
  }
</script>
  
<style scoped>
</style>
  