<template>
  <div class="container">
    <h1 class="my-5">Terms and Conditions</h1>
    <h3>1. Introduction</h3>
    <p>
      Welcome to <b>PROP</b>FACE.com, .sg or .com.sg ("<b>PROP</b>FACE", "we," "our," or "us"). This platform is
      owned and operated by <b>PROP</b>FACE Pte Ltd ([Enter Co.Reg.]), with its registered office at [Enter
      Address]. These Terms of Use ("Terms") govern your access to and use of our websites and services.
      By accessing or using our platform, you agree to comply with these Terms.
    </p>

    <h3 class="mt-4">2. User Representation</h3>
    <p>
      By using our platform, you represent and warrant that:
    </p>
    <ul>
      <li>
        You are at least 18 years old
      </li>
      <li>
        You possess the legal right and ability to enter into a binding agreement.
      </li>
      <li>
        All information you provide to us is accurate, complete, and current.
      </li>
      <li>
        You will use the platform in compliance with all applicable laws and regulations.
      </li>
    </ul>

    <h3 class="mt-4">3. Intellectual Property Ownership</h3>
    <p>
      All content on our platform, including text, graphics, logos, images, and software, is the property of
      <b>PROP</b>FACE or its content suppliers and is protected by Singapore and international copyright laws.
      Unauthorized use of our intellectual property is strictly prohibited.
    </p>

    <h3 class="mt-4">4. Copyright Materials</h3>
    <p>
      Users may upload or submit content, such as property listings and photos. By submitting content,
      you grant us a non-exclusive, royalty-free, worldwide, transferable license to use, reproduce,
      distribute, and display such content on our platform. You represent and warrant that you have the
      right to grant this license and that your content does not infringe on any third-party rights.
    </p>

    <h3 class="mt-4">5. Services Rendered by Users</h3>
    <p>
      Users may be allowed to offer services, such as property management or real estate brokerage,
      through our platform. Any agreements or transactions made between users and service providers
      are solely between those parties. We do not endorse or guarantee any services rendered by users.
    </p>

    <h3 class="mt-4">6. Subscription Fees and Credits</h3>
    <p>
      Subscription fees for accessing certain features of our platform are non-transferable and non-
      refundable. By subscribing to our services, you agree to pay the applicable fees and taxes.
    </p>
    <ul class='mt-2' style="list-style-type: none">
      <li>6.1 Purchase of Credits</li>
    </ul>
    <ul class='mt-2' style="list-style-type: none">
      <li>
        <p>
          Users may purchase credits, which can be used to access certain functions and privileges on
          an "on-demand" basis according to user entitlements. Credits may or may not be subject to
          expiry, depending on the user subscription package.
        </p>
      </li>
    </ul>

    <h3 class="mt-4">7. Special Features</h3>
    <ul class='mt-2' style="list-style-type: none">
      <li>7.1 Hybrid-Listing</li>
    </ul>
    <ul  class='mt-2' style="list-style-type: none">
      <li>
        <p>
          Our platform offers a Hybrid-Listing feature, allowing property owners to list and own their
          listings. Property owners have the option to assign their listings to estate agent subscribers
          with a digital term-sheet specifying the commission, type (exclusivity), and duration of the
          assignment. The arrangement is strictly between the property owner and the estate agent,
          and the platform is not implicated in the event of a dispute.
        </p>
      </li>
    </ul>
    <ul class='mt-2' style="list-style-type: none">
      <li>7.2 Property Widget with Referral Code</li>
    </ul>
    <ul  class='mt-2' style="list-style-type: none">
      <li>
        <p>
          Estate agents or agency subscribers can send invite links to their contacts to list properties
          directly through the Property Widget. If the property owner opts to assign the listing to the
          estate agent, it will be captured under the agent's leads. Estate agents are prohibited from
          abusing this feature, including using it for spamming purposes.
        </p>
      </li>
    </ul>
    <ul class='mt-2' style="list-style-type: none">
      <li>7.3 Feng Shui Map</li>
    </ul>
    <ul  class='mt-2' style="list-style-type: none">
      <li>
        <p>
          The Feng Shui Map feature offers enriched content based on specific information provided
          by the property owner or advertiser. This feature aims to make property listings unique by
          incorporating ancient wisdom to identify attributes of the property guided by general
          Chinese metaphysics practices. It is not intended to be relied upon or influence buyers or
          tenants in their purchasing or renting decisions.
        </p>
      </li>
    </ul>
    <ul class='mt-2' style="list-style-type: none">
      <li>7.4 Verification of Property Ownership</li>
    </ul>
    <ul  class='mt-2' style="list-style-type: none">
      <li>
        <p>
          Estate agents may verify or endorse the accuracy or authenticity of the property being listed
          from the supporting ownership documents and any available referencing resources.
          However, the platform disclaims any responsibility for the accuracy or authenticity of such
          verifications or endorsements.
        </p>
      </li>
    </ul>

    <h3 class="mt-4">8. AI-Generated Content Services and Social Media Posting</h3>
    <p>
      Our platform may offer AI-generated content services and the ability to post to social media. You
      acknowledge and agree that:
    </p>
    <ul>
      <li>
        AI-generated content is driven by autonomous decision-making processes and may produce
        responses in different interpretations.
      </li>
      <li>
        We do not guarantee the accuracy, reliability, or completeness of AI-generated content.
      </li>
      <li>
        You use AI-generated content at your own discretion and risk.
      </li>
      <li>
        When posting to social media, whether on your own accord or through our platform’s
        channels, you are responsible for complying with the privacy policies and terms of service
        respectively.
      </li>
    </ul>

    <h3 class="mt-4">9. Property Database Disclaimers</h3>
    <p>
      Our property database is compiled from legally obtained sources for reference purposes. We do not
      warrant the accuracy, timeliness, or completeness of the property information. Users should exercise
      their own discretion when relying on the database for listings and purchasing or renting decisions.
    </p>

    <h3 class="mt-4">10. Rating System and Scoring Model</h3>
    <p>
      Our platform features a rating system and scoring model to display search priorities and the
      convenience of listing results. This system is not warranted and is intended solely as a guide. It is not
      designed to influence user decisions.
    </p>

    <h3 class="mt-4">11. Indemnity Clause</h3>
    <p>
      You agree to indemnify and hold harmless <b>PROP</b>FACE, its affiliates, officers, agents, and employees
      from any claims, damages, losses, liabilities, and expenses (including legal fees) arising out of or
      related to your use of our platform, your violation of these Terms, or your infringement of any
      intellectual property or other rights of any third party.
    </p>

    <h3 class="mt-4">12. Commission Guidelines</h3>
    <p>
      If you engage in transactions involving commissions through our platform, you agree to comply with
      all relevant laws and regulations in Singapore, including the Estate Agents Act. All commission rates
      and payment terms should be clearly stated and agreed upon in writing between the involved
      parties.
    </p>

    <h3 class="mt-4">13. Digital Co-Broking and Commission Sharing</h3>
    <ul class='mt-3' style="list-style-type: none">
      <li>13.1 Digital Co-Broking in Singapore</li>
    </ul>
    <ul  class='mt-2' style="list-style-type: none">
      <li>
        <p>
          <b>PROP</b>FACE facilitates digital co-broking among estate agents in Singapore in accordance with
          guidelines set forth by the Council for Estate Agencies (CEA). Estate agents agree to adhere
          to the rules of engagement prescribed by the CEA, including but not limited to:
        </p>
      </li>
    </ul>
    <ul>
      <li>
        Ensuring transparency and fairness in commission sharing arrangements.
      </li>
      <li>
        Providing accurate and timely information to clients and co-broking partners.
      </li>
      <li>
        Resolving disputes in a professional manner without involving or implicating <b>PROP</b>FACE.
      </li>
    </ul>
    <ul class='mt-3' style="list-style-type: none">
      <li>13.2 Co-Broking Between Singapore and Malaysia</li>
    </ul>
    <ul  class='mt-2' style="list-style-type: none">
      <li>
        <p>
          <b>PROP</b>FACE also offers co-broking opportunities between estate agents in Singapore and
          Malaysia, adhering to the rules and guidelines established by the CEA in Singapore and the
          Malaysian Institute of Estate Agents (MIEA). Users engaging in co-broking activities between
          these countries agree to comply with:
        </p>
      </li>
    </ul>
    <ul>
      <li>
        Applicable tax laws and regulations in both Singapore and Malaysia.
      </li>
      <li>
        Foreign purchaser investment rules and restrictions in each respective country.
      </li>
      <li>
        Guidelines on property listings and transactions involving overseas properties.
      </li>
    </ul>
    <ul class='mt-3' style="list-style-type: none">
      <li>13.3 Overseas Property Listings</li>
    </ul>
    <ul  class='mt-2' style="list-style-type: none">
      <li>
        <p>
          Curated overseas property listings are made available on PROPFACE for users interested in
          international real estate investments. Users acknowledge and agree that:
        </p>
      </li>
    </ul>
    <ul>
      <li>
        <b>PROP</b>FACE does not endorse or guarantee the accuracy or legality of overseas property
        listings.
      </li>
      <li>
        Transactions involving overseas properties are subject to local laws, regulations, and taxation
        policies.
      </li>
      <li>
        Users should conduct their own due diligence and seek professional advice when engaging in
        transactions related to overseas properties.
      </li>
    </ul>

    <h3 class="mt-4">14. Limitation of Liability</h3>
    <p>
      To the maximum extent permitted by law, <b>PROP</b>FACE shall not be liable for any direct, indirect,
      incidental, special, or consequential damages resulting from your use of our platform or inability to
      access or use our platform.
    </p>

    <h3 class="mt-4">15. Applicable Laws and Guidelines</h3>
    <p>
      These Terms are governed by the laws of Singapore. You agree to submit to the exclusive jurisdiction
      of the courts in Singapore for any disputes arising out of or relating to these Terms or your use of our
      platform.
    </p>

    <h3 class="mt-4">16. Changes to Terms</h3>
    <p>
      We reserve the right to modify these Terms at any time. Any changes will be effective immediately
      upon posting on our platform. Your continued use of our platform after any modifications constitutes
      your acceptance of the revised Terms.
    </p>


  </div>



</template>

<script>
export default {
  name: 'TermCondition',

};
</script>

<style scoped>
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
</style>