// store.js
// import Vue from 'vue';
import Vuex from 'vuex';

// Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    CONSTANT : {
      IMAGE_URL: 'https://pv-staging.tess.gg/storage/',
      DEFAULT_IMAGE: 'frontend/images/no_profile.jpg'
    },
    isAuthenticated: !!localStorage.getItem('userToken'), // Initialize based on localStorage
    user: JSON.parse(localStorage.getItem('user')) || {}, // Initialize based on localStorage
    userProfile: {} // Initialize based on localStorage
  },
  mutations: {
    UPDATE_LOCAL_STORAGE_USER(state) {
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    SET_AUTHENTICATED(state, status) {
      state.isAuthenticated = status;
    },
    SET_USER(state, user) {
      state.user = user;
      this.commit('UPDATE_LOCAL_STORAGE_USER');
    },
    UPDATE_USER_FIELD(state, payload) {
      state.user = { ...state.user, ...payload };
      this.commit('UPDATE_LOCAL_STORAGE_USER');
    },
    GET_USER(state, user) {
      state.userProfile = user;
    }
  },
  actions: {
    profileUpdate({ commit }, userdata) {
      commit('GET_USER', userdata);
    },
    login({ commit }, userdata) {
      // Simulate an API call to log in
      localStorage.setItem('userToken', userdata.token); // Store token in localStorage
      localStorage.setItem('user', JSON.stringify(userdata.user)); // Store user data in localStorage
     
      commit('SET_USER', userdata.user);
      commit('SET_AUTHENTICATED', true);
    },
    logout({ commit }) {
      localStorage.removeItem('userToken');
      localStorage.removeItem('user');
      localStorage.removeItem('googleIdToken');
      localStorage.removeItem('facebookAccessToken');
 
      
      commit('SET_USER', {});
      commit('SET_AUTHENTICATED', false);
    },
    async fetchUserProfile({ commit }) {
      console.log('aaa' + this.$axios);
      try {
        const response = await this.$axios.post('/api/profile123', {
          api_token: '115|7BImVFhE5VAXNOknqEc7IgBhow53ufi6aIKw1lfq670e3fca'
        });
        // const response = await this.$axios.post('/public/api/profile');
        console.log(response.data);
        commit('GET_USER', response.data);
      } catch (error) {
        console.error('Failed to fetch profile:', error);
      }
    },
  }
});

export default store;