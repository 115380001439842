<template>
  <section>
    <h1 class="text-center pt-5 pb-2">How It Works</h1>
  </section>

  <div class="container mt-4">
    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 text-center">
            <img src="/frontend/images/hit-work-1.png" alt="hiw1">
          </div>
          <div class="col-md-6 align-content-center">
            <div class="text-content">
              <h4 class="mb-3 fw-bold">Hybrid Listing</h4>
              <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
              <div class="btn-container">
<!--                <a class="comman-btn-2 mt-4" href="/hybrid-listing" >Find Out More</a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 align-content-center">
            <h4 class="mb-3 fw-bold">Feng Shui Mapping</h4>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
            <div class="btn-container">
<!--              <a class="comman-btn-2 mt-4" href="/feng-shui-mapping">Find Out More</a>-->
            </div>
          </div>
          <div class="col-md-6 text-center">
            <img style="" src="/frontend/images/hit-work-2.png" alt="hiw2">
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 text-center">
            <img style="" src="/frontend/images/hit-work-3.png" alt="hiw3">
          </div>
          <div class="col-md-6 align-content-center">
            <h4 class="mb-3 fw-bold">Al-Assist & Creatives</h4>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
            <div class="btn-container">
<!--              <a class="comman-btn-2 mt-4" href="/ai-assits-creatives">Find Out More</a>-->
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 align-content-center">
            <h4 class="mb-3 fw-bold">X-Border Properties</h4>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
            <div class="btn-container">
<!--              <a class="comman-btn-2 mt-4" href="/x-border-properties">Find Out More</a>-->
            </div>
          </div>
          <div class="col-md-6 text-center">
            <img style="" src="/frontend/images/hit-work-4.png" alt="hiw4">
          </div>
        </div>
      </div>
    </section>
  </div>

  <section class="testimonial-sec mt-5">
    <div class="container">
      <div class="comman-title text-center">
        <span>Testimonials</span>
        <h3>what our customer say about us</h3>
      </div>
      <div class="testimonail-carousel owl-carousel owl-theme">
        <div class="item">
          <div class="testimoanil-bx">
            <div class="client-header">
              <figure>
                <img src="frontend/images/agent1.png">
              </figure>
              <div class="client-nm">
                <h4>Jane Cooper</h4>
                <div class="rating-show">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
              </div>
            </div>
            <div class="testimoanil-content">
              <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="testimoanil-bx">
            <div class="client-header">
              <figure>
                <img src="frontend/images/agent2.jpg">
              </figure>
              <div class="client-nm">
                <h4>Ralph Edwards</h4>
                <div class="rating-show">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
              </div>
            </div>
            <div class="testimoanil-content">
              <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="testimoanil-bx">
            <div class="client-header">
              <figure>
                <img src="frontend/images/agent3.png">
              </figure>
              <div class="client-nm">
                <h4>Brooklyn Simmons</h4>
                <div class="rating-show">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
              </div>
            </div>
            <div class="testimoanil-content">
              <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="testimoanil-bx">
            <div class="client-header">
              <figure>
                <img src="frontend/images/agent1.png">
              </figure>
              <div class="client-nm">
                <h4>Jane Cooper</h4>
                <div class="rating-show">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
              </div>
            </div>
            <div class="testimoanil-content">
              <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="testimoanil-bx">
            <div class="client-header">
              <figure>
                <img src="frontend/images/agent2.jpg">
              </figure>
              <div class="client-nm">
                <h4>Ralph Edwards</h4>
                <div class="rating-show">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
              </div>
            </div>
            <div class="testimoanil-content">
              <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="testimoanil-bx">
            <div class="client-header">
              <figure>
                <img src="frontend/images/agent3.png">
              </figure>
              <div class="client-nm">
                <h4>Brooklyn Simmons</h4>
                <div class="rating-show">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
              </div>
            </div>
            <div class="testimoanil-content">
              <p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
  export default {
    name: 'FeaturePage',

    mounted(){
      window.$('.testimonail-carousel').owlCarousel({
        loop: false,
        margin: 25,
        nav: false,
        dots: true,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 2
          },
          1000: {
            items: 3
          }
        }
      });
    }
  }


</script>

<style>
  .text-content p{
    line-height: 1.8em;
  }

  .btn-container {
    display: flex;
    justify-content: start;
  }


</style>