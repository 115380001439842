<template>    
   <section class="listing-se">
        <div class="container">
            
            <div class="filter-bar-se mb-0">
                <div class="comman-title mb-0">
                    <h3 class="mb-2">My Property</h3>
                    <!-- <span>Showing 10 properties</span> -->
                </div>
                <div class="filter-right-bar">
                    <!-- <div class="dropdown">
                        <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                            <img src="frontend/images/filter-ico.svg"> Filter By
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                            <li><a class="dropdown-item" href="#">111</a></li>
                            <li><a class="dropdown-item" href="#">111</a></li>								
                        </ul>
                    </div> -->
                    <div class="dropdown">
                        <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                            <img src="frontend/images/Sort-By.svg"> Sort By : {{ selectedSortOption }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
                            <li v-for="option in sortOptions" :key="option.value">
                                <a 
                                class="dropdown-item sortBy" 
                                href="#" 
                                @click.prevent="sortData(option.value)"
                                >
                                {{ option.label }}
                                </a>
                            </li>							
                        </ul>
                    </div>
                </div>
            </div>
            <LoaderCust :isLoading="isLoading" />
            <div v-if="!isLoading && !items.length" class="alert alert-warning">No items found.</div>
            <div class="row row-cols-lg-3 row-cols-sm-2">                
                <div class="col" v-for="item in items" :key="item.id">
                    <router-link :to="`/property-detail/${item.id}`">
                        <div class="project-bx">
                            <div class="edit-pro">
                                <router-link :to="`/post-property-listing/${item.id}`">
                                    <i class="fa fa-pencil"></i>
                                </router-link>
                            </div>
                            <div class="heart-pro">
								<a href="#" :class="{'active': item.is_in_wishlist == '1'}"><i class="fa fa-heart-o"></i></a>
							</div>
                            

                            <picture v-if="item.actual_unit_photo && item.actual_unit_photo.length > 0">
                                <img :src="getImageUrl(item.actual_unit_photo[0].photo_name)" @error="handleImageError" alt="Property Image" />
                            </picture>
                            <picture v-else>
                                <img :src="CONSTANT.DEFAULT_NO_IMAGE"  @error="handleImageError" alt="Property Image" />
                            </picture>
                            <div class="project-info">
                                <div class="d-md-flex mb-2 align-items-center">
                                    <h3><a href="">{{ item.property_name }}</a></h3>
                                    <div class="price-pro ms-md-auto">
                                        <span><small>s$</small> {{item.asking_price_sale_rent}}</span>
                                    </div>
                                </div>
                                <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>{{ item.street }},{{ item.area_hdb_estate }},{{ item.district }}</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>{{item.no_of_rooms}}</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>{{item.no_car_park_lots}}</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>{{item.land_area}} {{item.land_area_unit}}</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">{{item.property_type_details.parent_id_top_level_1_code}}</a>
                                    <a href="#">{{item.property_type_details.code}}</a>
                                    <a href="#" v-if="item.land_tenure"> {{ getlandTenue(item.land_tenure)}} </a>
                                </div>
                                <ul>
                                    <li v-if="item.is_direct_owner">
                                        <figure>
                                            <img src="frontend/images/specify-ico1.png">
                                        </figure>
                                        <small>Direct<br>Owner</small>
                                    </li>
                                    <li  v-if="item.is_sing_pass || item.is_corp_pass ">
                                        <figure>
                                            <img src="frontend/images/specify-ico2.png">
                                        </figure>
                                        <small>SingPass<br>Verified </small>
                                    </li>
                                    <li v-if="item.is_document_approved">
                                        <figure>
                                            <img src="frontend/images/specify-ico3.png">
                                        </figure>
                                        <small>Documents<br>Verified</small>
                                    </li>
                                    <li v-if="item.is_FSM_linked">
                                        <figure>
                                            <img src="frontend/images/specify-ico4.png">
                                        </figure>
                                        <small>Fengshui<br>Map</small>
                                    </li>
                                    <li v-if="item.is_assigned_to_the_agent">
                                        <figure>
                                            <img src="frontend/images/specify-ico5.png">
                                        </figure>
                                        <small>Hybrid-Listing<br>Owners </small>
                                    </li>
                                    <li v-if="item.is_below_mean_value">
                                        <figure>
                                            <img src="frontend/images/Down-arrow.svg">
                                        </figure>
                                        <small>BMV</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </router-link>
                </div>                
            </div>
        </div>
    </section>
</template>
  
  <script>
  import LoaderCust from '@/components/LoaderCust.vue';
  import { CONSTANT } from '@/constants/constants';
  import { mapState } from 'vuex';
  export default {
    name: 'MyProperty', 
    components: {
        LoaderCust,
    },
    data() {
        return {    
            isLoading: true,   
            CONSTANT, 
            items: [],  
            optionsLandTenuresList: [],  
            selectedSortOption: 'Newest',
            sortOptions: [
                { label: 'Recommended', value: 'Recommended' },
                { label: 'Relevance', value: 'Relevance' },
                { label: 'Newest', value: 'Newest' },
                { label: 'Lowest Price', value: 'Lowest Price' },
                { label: 'Highest Price', value: 'Highest Price' },
                { label: 'Lowest Floor Area', value: 'Lowest Floor Area' },
                { label: 'Highest Floor Area', value: 'Highest Floor Area' },
                { label: 'Lowest P SF', value: 'Lowest P SF' },
                { label: 'Highest P SF', value: 'Highest P SF' },
            ],   
        }
    },
    computed: {
      ...mapState(['isAuthenticated', 'user']),
    },
    created() {
       this.fetchOptions();
    },
    methods: {

        
        
        async fetchOptions() {
            // Get Land Tenure list
            try {
                const response = await this.$axios.post('api/pr-land-tenures-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0                     
                });
                this.optionsLandTenuresList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/property-list',
                {                    
                    user_id : this.user.id,
                    api_token : token, 
                    sort_by_field : 'Newest', 
                });
                this.items  = response.data.output;
            } catch (error) {
                console.error('Error fetching options:', error);
            }finally {
                this.isLoading = false;
            }
            
        },
        getImageUrl(photoName) {        
            return photoName ? `${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${photoName}` : CONSTANT.DEFAULT_IMAGE;
        },
        
        handleImageError(event) {
            event.target.src = CONSTANT.DEFAULT_IMAGE;
        },

        getlandTenue(value) {
            const item = this.optionsLandTenuresList.find(option => option.value === value);
            return item ? item.label : 'Not found';
        },
        
        async sortData(sortBy) {
            this.isLoading = true;
            this.items = [];
            this.selectedSortOption = sortBy; 
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/property-list',
                {                    
                    user_id : this.user.id,
                    api_token : token, 
                    sort_by_field : sortBy, 
                });
                this.items  = response.data.output;
            } catch (error) {
                console.error('Error fetching options:', error);
            }finally {
                this.isLoading = false;
            }
        },
      
    }   
    
  }
  </script>
  
  <style scoped>
  /* Add styles specific to the About Us page here */
  </style>
  