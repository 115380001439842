<template>
    
    <header class="main-header">			
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container-fluid">
                <a class="navbar-brand" href="/"><img alt="Propverse" src="frontend/images/logo.png"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <!-- <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="#">Home</a>
                        </li> -->							
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Buy
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="/agents">Get an Agent</a></li>
                                <li><a class="dropdown-item" href="/ExploreMore">Do It Yourself (DIY)</a></li>								
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Rent
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="/agents">Get an Agent</a></li>
                                <li><a class="dropdown-item" href="/ExploreMore">DIY - Renter/Tenant</a></li>								
                                <li><a class="dropdown-item" href="/post-property-listing">DIY - Owner/Landlord</a></li>								
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Sell
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="/agents">Get an Agent</a></li>
                                <li><a class="dropdown-item" href="/post-property-listing">Do It Yourself (DIY)</a></li>								
                            </ul>
                        </li>
<!--                        <li class="nav-item dropdown">-->
<!--                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">-->
<!--                                Discover-->
<!--                            </a>-->
<!--                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">-->
<!--                                <li><a class="dropdown-item" href="/hybrid-listing">Hybrid Listing</a></li>-->
<!--                                <li><a class="dropdown-item" href="/feng-shui-mapping">Feng Shui Mapping</a></li>-->
<!--                                <li><a class="dropdown-item" href="/ai-assist-creatives">AI-Assist & Creatives</a></li>-->
<!--                                <li><a class="dropdown-item" href="/x-border-properties">X-Border Properties</a></li>-->
<!--                            </ul>-->
<!--                        </li>-->
                    </ul>						                    
                    <div class="list-property-btn ms-md-auto"  v-if="isAuthenticated">
<!--                        <a href="/heart-authenticator" class="fav-header-ico me-lg-3">-->
<!--                            <img src="frontend/images/heart-ico.svg">-->
<!--                        </a>-->
                        <div class="dropdown me-lg-3">
                            <a class="comman-btn-1" href="javascript:;" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img :src="computedImageSrc" @error="handleImageError" alt="User img">  {{ user.name }}
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="/profile">My Profile</a></li>
                                <li v-if="!user.is_mobile_verified"><router-link class="dropdown-item" :to="{ name: 'verifyNumber' }">Verify Number</router-link></li>
                                <li><router-link class="dropdown-item" :to="{ name: 'Packages' }">Purchase Package</router-link></li>
                                <li><a class="dropdown-item" href="/post-property-listing">Post Property</a></li>	
                                      <li><a class="dropdown-item" href="/my-property">My Property</a></li>							
                                <li><a class="dropdown-item" href="/post-project-listing">Post Project </a></li>								
                          
                              <li><a class="dropdown-item" href="/my-project">My Project</a></li>	
<!--                                <li><a class="dropdown-item" href="/my-wishlist">My Wishlist</a></li>-->
                                <li><a class="dropdown-item" href="/reports">Reports</a></li>
                                <li><a class="dropdown-item text-danger" href="/" @click="logout">Logout</a></li>
                            </ul>
                        </div>							
                        <a href="/post-property-listing" class="comman-btn-2">Add Listing</a>
                    </div>
                    <div class="list-property-btn ms-md-auto" v-else>
                        <a href="#" class="fav-header-ico me-md-3">
                            <img src="frontend/images/heart-ico.svg">
                        </a>
                        <a href="/sign-in" class="comman-btn-1 me-md-3">Login</a>
                        <a href="/sign-up" class="comman-btn-2">Sign Up!</a>
                    </div>
                </div>
            </div>
        </nav>
    </header>


</template>
  <script>
  import { CONSTANT } from '@/constants/constants';
  import { mapState } from 'vuex';
  export default {
    name: 'HeaderPart',
    computed: {
        ...mapState(['isAuthenticated', 'user', 'userProfile']),
        computedImageSrc() {
            //console.log('--'+ `${CONSTANT.IMAGE_URL}/imagesProfile/${this.userProfile.profile_photo_name}`);
            // Return user profile image or default image
            // return this.user.profileImage || CONSTANT.DEFAULT_IMAGE;
            return this.userProfile.profile_photo_name ? `${CONSTANT.IMAGE_URL}/imagesProfile/${this.userProfile.profile_photo_name}` : CONSTANT.DEFAULT_IMAGE;
        }
    },
    // data() {
    //     console.log(this.user.profile_photo_name)
    //     return {
    //         return this.user.profileImage ? `${CONSTANT.IMAGE_URL}/${this.user.profileImage}` : CONSTANT.DEFAULT_IMAGE;
    //         imageSrc: CONSTANT.IMAGE_URL, // Initial image source
    //         defaultImageSrc: CONSTANT.DEFAULT_IMAGE // Default image source
    //     };
    // },
    methods: {
        ...mapState(['logout']),
        async logout() {
            try {
                await this.$store.dispatch('logout'); // Call Vuex logout action
                this.$router.push('/sign-in'); // Redirect to sign-in page or any other page
            } catch (error) {
                console.error('Logout failed:', error);
            }
        },
        handleImageError(event) {
            event.target.src = this.defaultImageSrc; // Set default image on error
        }
    }
  }
  </script>

  